import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {PixFeesCalculatorData} from '../../api/feesCalculator'
import InfoPopover from '../InfoPopover'
import TableFees from '../TableFees'
import parseToHTML from 'html-react-parser'

const PixFees = ({fees}: {fees: PixFeesCalculatorData}) => {
	const {t} = useTranslation()

	return (
		<TableFees
			feesForBuyer={{
				grossAmount: fees.feesForBuyer.grossAmount,
				netAmount: fees.feesForBuyer.netAmount,
				totalFeesAmount: fees.feesForBuyer.totalFeesAmount,
				pagarme: [
					{
						head: t('pix-fee'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForBuyer.pixFees.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{t('pix-fee-percentage', {
											percentage: fees.feesForBuyer.pixFees.percentage
										})}
									</Typography>
								</InfoPopover>
							</Box>
						)
					}
				],
				excofy: [
					{
						head: t('sales-commission-fee'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForBuyer.platformFees.variableFee.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{parseToHTML(
											t('sales-commission-fee-percentage', {
												percentage:
													fees.feesForBuyer.platformFees.variableFee.percentage
											})
										)}
									</Typography>
								</InfoPopover>
							</Box>
						)
					}
				]
			}}
			feesForSeller={{
				grossAmount: fees.feesForRecipient.grossAmount,
				netAmount: fees.feesForRecipient.netAmount,
				totalFeesAmount: fees.feesForRecipient.totalFeesAmount,
				pagarme: [
					{
						head: t('pix-fee'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForRecipient.pixFees.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{t('pix-fee-percentage', {
											percentage: fees.feesForRecipient.pixFees.percentage
										})}
									</Typography>
								</InfoPopover>
							</Box>
						)
					}
				],
				excofy: [
					{
						head: t('sales-commission-fee'),
						body: (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center'
								}}
							>
								<Typography variant="body2">
									{fees.feesForRecipient.platformFees.variableFee.totalAmount}
								</Typography>
								<InfoPopover>
									<Typography variant="body2">
										{parseToHTML(
											t('sales-commission-fee-percentage', {
												percentage:
													fees.feesForRecipient.platformFees.variableFee
														.percentage
											})
										)}
									</Typography>
								</InfoPopover>
							</Box>
						)
					}
				]
			}}
		/>
	)
}

export default PixFees
