import {TextField} from '@mui/material'
import {useFormik} from 'formik'
import {regex} from '../helpers/regex'
import {SxProps, Theme} from '@mui/material/styles'
import {useEffect, useState} from 'react'

export default function InputPercentage({
	label,
	initialValue,
	onChange,
	sx
}: {
	label: string
	initialValue?: number
	onChange: (value: number) => void
	sx?: SxProps<Theme>
}) {
	const [firstLoad, setFirstLoad] = useState(true)

	const formik = useFormik({
		initialValues: {
			percentage: ''
		},
		onSubmit: () => {}
	})

	useEffect(() => {
		if (firstLoad && initialValue) {
			formik.setFieldValue(
				'percentage',
				regex.formatBRLCurrency(
					Number.parseFloat(String(initialValue)).toFixed(2) // add 2 decimal places to price from database
				) || ''
			)
			setFirstLoad(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstLoad, initialValue])

	return (
		<TextField
			name="percentage"
			label={label}
			size="small"
			value={formik.values.percentage}
			onChange={event => {
				event.target.value = regex.formatBRLCurrency(event.target.value)
				formik.setFieldValue('percentage', event.target.value)
				onChange(regex.parseBRLCurrencyToFloat(String(event.target.value || 0)))
			}}
			InputProps={{
				endAdornment: '%'
			}}
			sx={sx}
		/>
	)
}
