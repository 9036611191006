export const colorConvert = {
	hexToRgb: (hex: string) => {
		const hexValue = hex.replace('#', '')
		const r = parseInt(hexValue.substring(0, 2), 16)
		const g = parseInt(hexValue.substring(2, 4), 16)
		const b = parseInt(hexValue.substring(4, 6), 16)
		return [r, g, b]
	},
	rgbToHex: (rgb: number[]) => {
		const r = rgb[0].toString(16).padStart(2, '0')
		const g = rgb[1].toString(16).padStart(2, '0')
		const b = rgb[2].toString(16).padStart(2, '0')
		return `#${r}${g}${b}`
	},
	isDark: (hex: string) => {
		const [r, g, b] = colorConvert.hexToRgb(hex)
		const luminance = colorConvert.getLuminance(r, g, b)
		return luminance < 0.5
	},
	getLuminance: (r: number, g: number, b: number) => {
		const a = [r, g, b].map(v => {
			v /= 255
			return v <= 0.03928 ? v / 12.92 : Math.pow((v + 0.055) / 1.055, 2.4)
		})
		return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722
	},
	colorDistance: (rgb1: number[], rgb2: number[]) => {
		const [r1, g1, b1] = rgb1
		const [r2, g2, b2] = rgb2
		return Math.sqrt((r2 - r1) ** 2 + (g2 - g1) ** 2 + (b2 - b1) ** 2)
	},
	isNearBlackOrWhite: (hex: string) => {
		const rgb = colorConvert.hexToRgb(hex)

		// Define RGB values for black and white
		const black = [0, 0, 0]
		const white = [255, 255, 255]

		// Calculate distances
		const distanceToBlack = colorConvert.colorDistance(rgb, black)
		const distanceToWhite = colorConvert.colorDistance(rgb, white)

		// Define thresholds for being "close" to black or white (this can be adjusted)
		const threshold = 100

		if (distanceToBlack < threshold) {
			return 'black'
		} else if (distanceToWhite < threshold) {
			return 'white'
		} else {
			return 'neither'
		}
	},
	getContrastingColor: (hex: string) => {
		const nearColor = colorConvert.isNearBlackOrWhite(hex)

		switch (nearColor) {
			case 'black': {
				return '#FFFFFF'
			}
			case 'white': {
				return '#000000'
			}
			default: {
				return hex
			}
		}
	}
}

export const generateColorDark = (
	color: string,
	colorIncrement: number = 0
) => {
	return colorConvert.rgbToHex(
		colorConvert
			.hexToRgb(color)
			.map((c: number) => (c - colorIncrement < 0 ? 0 : c - colorIncrement))
	)
}

export const generateColorLight = (
	color: string,
	colorIncrement: number = 0
) => {
	return colorConvert.rgbToHex(
		colorConvert
			.hexToRgb(color)
			.map((c: number) => (c + colorIncrement > 255 ? 255 : c + colorIncrement))
	)
}
