import {Theme} from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import useTheme from '@mui/material/styles/useTheme'
import FullSection from '../layout/utils/FullSection'
import LogoType from './LogoType'
import {styled} from '@mui/system'
import {
	FaFacebookSquare,
	FaInstagramSquare,
	FaYoutubeSquare
} from 'react-icons/fa'
import {Link} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {LANDING_PAGE_URL} from '../../config'

const Footer = () => {
	const {t} = useTranslation()

	const theme = useTheme()
	return (
		<FullSection grey={50} height="auto" mt={5}>
			<Box
				sx={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'space-between',
					gap: '2rem'
				}}
			>
				<Box>
					<LogoType
						monoColorLight={theme.palette.grey[500]}
						monoColorDark={theme.palette.grey[600]}
					/>
					<Typography
						variant="body2"
						sx={theme => ({
							color: theme.palette.grey[500]
						})}
					>
						{t('experience-commerce-platform')}
					</Typography>
					<Typography
						variant="caption"
						sx={theme => ({
							color: theme.palette.grey[500]
						})}
					>
						Copyright © 2023 Excofy.
					</Typography>
					<br />
					<br />
					<Typography
						variant="body2"
						sx={theme => ({
							color: theme.palette.grey[600]
						})}
					>
						<a
							href={LANDING_PAGE_URL + '/terms-of-use'}
							target="_blank"
							rel="noreferrer"
							style={{textDecoration: 'none', color: 'inherit'}}
						>
							{t('terms')}
						</a>
						{' | '}
						<a
							href={LANDING_PAGE_URL + '/privacy-policy'}
							target="_blank"
							rel="noreferrer"
							style={{textDecoration: 'none', color: 'inherit'}}
						>
							{t('policy')}
						</a>
					</Typography>
				</Box>
				<Box
					sx={theme => ({
						textAlign: 'center',
						'@media (max-width: 425px)': {
							margin: '0 auto',
							padding: '0'
						}
					})}
				>
					<Typography
						variant="body2"
						sx={theme => ({
							color: theme.palette.grey[600],
							marginBottom: theme.spacing(1)
						})}
					>
						{t('follow')} Excofy
					</Typography>
					<Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
						<Link href="https://www.facebook.com/excofy" target="_blank">
							<FacebookIcon
								sx={{...style.iconFollow(theme), ...style.facebookIcon}}
							/>
						</Link>
						<Link
							href="https://www.instagram.com/excofy.platform"
							target="_blank"
						>
							<InstagramIcon
								sx={{...style.iconFollow(theme), ...style.instagramIcon}}
							/>
						</Link>
						<Link href="https://www.youtube.com/@excofy" target="_blank">
							<YoutubeIcon
								sx={{...style.iconFollow(theme), ...style.youtubeIcon}}
							/>
						</Link>
					</Box>
				</Box>
			</Box>
		</FullSection>
	)
}

const FacebookIcon = styled(FaFacebookSquare)``
const InstagramIcon = styled(FaInstagramSquare)``
const YoutubeIcon = styled(FaYoutubeSquare)``

const style = {
	iconFollow: (theme: Theme) => {
		return {
			color: theme.palette.grey[500],
			fontSize: '1.8rem',
			marginLeft: theme.spacing(1)
		}
	},
	facebookIcon: {
		marginLeft: 0,
		'&:hover': {
			color: '#1b74e4'
		}
	},
	instagramIcon: {
		'&:hover': {
			color: '#cb7b12'
		}
	},
	youtubeIcon: {
		'&:hover': {
			color: '#FF0000'
		}
	}
}
export default Footer
