import Breadcrumbs from '@mui/material/Breadcrumbs'
import Container from '@mui/material/Container'
import {useTranslation} from 'react-i18next'
import {Theme} from '@mui/material/styles'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import {FaHome as HomeIcon} from 'react-icons/fa'
import {MdModeEdit as EditIcon} from 'react-icons/md'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'

import Link from '../../../components/navigation/Link'
import Footer from '../../../components/template/Footer'
import SectionTitle from '../../../components/typography/SectionTitle'
import {Status} from '../../../components/Status'
import useAuthStates from '../../../stores/useAuthStates'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import FormGroup from '@mui/material/FormGroup'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import {useCallback, useEffect, useState} from 'react'
import {useMutation, useQuery} from '@tanstack/react-query'
import {useNavigate} from 'react-router-dom'
import {regex} from '../../../helpers/regex'
import AlertDialog from '../../../components/AlertDialog'
import {LoadingDialog} from '../../../components/LoadingDialog'
import {SimpleDialog} from '../../../components/Dialog'
import ButtonLink from '../../../components/navigation/ButtonLink'
import parseToHTML from 'html-react-parser'
import usePaymentServicePagarmeStates from '../../../stores/usePaymentServicePagarmeStates'
import BackNavigation from '../../../components/navigation/BackNavigation'
import {
	activatePaymentServicePagarme,
	changePagarmeStatus,
	getPaymentServicePagarme
} from '../../../api/payment-service/pagarme'
import {FormAnnualRevenue} from './forms/FormAnnualRevenue'
import {price} from '../../../helpers/price'
import {FormTransferSettings} from './forms/FormTransferSettings'
import {PagarmeConfig} from '../../../entities/PagarmeConfig'
import {CircularProgress} from '@mui/material'
import {date} from '../../../helpers/date'
import useAppStates from '../../../stores/useAppStates'
import {DashboardFullSection} from '../../../components/template/DashboardFullSection'

export default function PaymentServicePagarmeSettings() {
	const {t} = useTranslation()
	const navigate = useNavigate()

	const {currentLang} = useAppStates()
	const {removePaymentService, company, onboarding, setOnboarding} =
		useAuthStates(state => state)

	const {
		status,
		setStatus,
		transferSettings,
		setTransferSettings,
		bankAccount,
		setBankAccount,
		companyAnnualRevenue,
		setCompanyAnnualRevenue,
		companyLegalRepresentative,
		setCompanyLegalRepresentative,
		setTransfers
	} = usePaymentServicePagarmeStates(state => state)

	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')
	const [openStatusList, setOpenStatusList] = useState(false)
	const [anchorElStatusList, setAnchorElStatusList] =
		useState<null | HTMLElement>(null)
	const [openAlertStatusChange, setOpenAlertStatusChange] =
		useState<boolean>(false)
	const [openFormAnnualRevenue, setOpenFormAnnualRevenue] =
		useState<boolean>(false)
	const [openFormTransferSettings, setOpenFormTransferSettings] =
		useState<boolean>(false)

	const {
		mutate: mutateChangePagarmeStatus,
		isLoading: isLoadingChangePaymentServiceStatus
	} = useMutation(changePagarmeStatus, {
		networkMode: 'always'
	})

	const handleChangePagarmetatus = useCallback(
		(newStatus: PagarmeConfig['status']) => {
			setAlertErrorMessage('')
			mutateChangePagarmeStatus(
				{status: newStatus, service: 'pagarme'},
				{
					onSuccess: ({error, responseStatusCode}) => {
						if (responseStatusCode === 200) {
							if (!onboarding.paymentService) {
								setOnboarding({
									...onboarding,
									paymentService: newStatus === 'active' ? true : false
								})
							}
							setStatus(newStatus)
							setOpenAlertStatusChange(false)
							removePaymentService()
						} else {
							setAlertErrorMessage(error?.message || t('error-server-default'))
						}
					},
					onError: (error, data) => {
						setAlertErrorMessage(t('error-server-default'))
						console.error(error)
					}
				}
			)
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[onboarding, t]
	)

	const {
		mutate: mutateActivatePaymentServicePagarme,
		isLoading: isLoadingActivatePaymentServicePagarme
	} = useMutation(activatePaymentServicePagarme, {
		networkMode: 'always'
	})

	const handleActivePaymentServicePagarme = useCallback(
		() => {
			setAlertErrorMessage('')

			if (status === 'deactivated') {
				handleChangePagarmetatus('active')
			} else {
				mutateActivatePaymentServicePagarme(undefined, {
					onSuccess: ({error, responseStatusCode, data}) => {
						if (responseStatusCode === 201 && data) {
							setOpenAlertStatusChange(false)
							setStatus(data.status)
						} else {
							setAlertErrorMessage(error?.message || t('error-server-default'))
						}
					},
					onError: (error, data) => {
						setAlertErrorMessage(t('error-server-default'))
						console.error(error)
					}
				})
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[status, t]
	)

	const handleClickStatusChange = (status: string) => {
		if (status === 'active') {
			handleChangePagarmetatus(status)
		} else {
			setOpenAlertStatusChange(true)
		}
	}

	const handleNavigateTo = (url: string) => {
		navigate(url)
	}

	useQuery(['payment-service-pagarme'], getPaymentServicePagarme, {
		refetchOnWindowFocus: false,
		networkMode: 'always',
		enabled:
			!companyLegalRepresentative || !bankAccount || !companyAnnualRevenue,
		onSuccess: ({data, responseStatusCode, error}) => {
			if (responseStatusCode === 200) {
				setStatus(
					data?.status || ('pending-activation' as PagarmeConfig['status'])
				)
				setBankAccount(data?.bankAccount || null)
				setCompanyAnnualRevenue(data?.companyAnnualRevenue || null)
				setCompanyLegalRepresentative(data?.companyLegalRepresentative || null)
				setTransferSettings(data?.transferSettings || null)
				setTransfers(data?.transfers || [])
			} else {
				setAlertErrorMessage(error?.message || t('error-server-default'))
			}
		},
		onError: error => {
			setAlertErrorMessage(t('error-server-default'))
			console.error(error)
		}
	})

	useEffect(() => {
		if (
			bankAccount &&
			companyAnnualRevenue &&
			companyLegalRepresentative &&
			transferSettings &&
			status === 'pending-setup'
		) {
			setStatus('pending-activation')
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		setStatus,
		bankAccount,
		companyAnnualRevenue,
		companyLegalRepresentative,
		transferSettings
	])

	return (
		<>
			<Container>
				<Breadcrumbs aria-label="breadcrumb">
					<Link
						to="/"
						title="Home Dashboard"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<HomeIcon />
					</Link>
					<Link
						to="/payment-services"
						style={{alignItems: 'center', display: 'flex'}}
					>
						<span>{t('payment-services')}</span>
					</Link>
					<span>{t('payment-service-pagarme-settings')}</span>
				</Breadcrumbs>
				<BackNavigation to="/payment-services" />
			</Container>
			<FormAnnualRevenue
				open={openFormAnnualRevenue}
				onClose={() => setOpenFormAnnualRevenue(false)}
			/>
			<FormTransferSettings
				open={openFormTransferSettings}
				onClose={() => setOpenFormTransferSettings(false)}
			/>
			<DashboardFullSection>
				<AlertDialog
					open={!!alertErrorMessage}
					severity="error"
					message={alertErrorMessage}
					onClose={() => setAlertErrorMessage('')}
				/>
				<LoadingDialog
					open={isLoadingChangePaymentServiceStatus}
					message={t('loading-change-payment-service-status')}
				/>
				<SimpleDialog
					open={openAlertStatusChange}
					onClose={() => setOpenAlertStatusChange(false)}
				>
					<Typography variant="h6" mb={1}>
						{t('alert-deactivate-payment-service-title')}
					</Typography>
					<Typography variant="body1">
						{t('alert-deactivate-payment-service-info-1')}
					</Typography>
					<Box sx={styles.simpleDialogActionsBox}>
						<Button
							variant="outlined"
							onClick={() => setOpenAlertStatusChange(false)}
						>
							{t('cancel')}
						</Button>
						<Button
							variant="contained"
							onClick={() => handleChangePagarmetatus('deactivated')}
							sx={{ml: 1}}
						>
							{t('to-deactivate')}
						</Button>
					</Box>
				</SimpleDialog>
				<Box component="form" autoComplete="off" mb={6}>
					<Box
						sx={{
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'flex-end',
							justifyContent: 'flex-start',
							gap: 2,
							mb: 2
						}}
					>
						<SectionTitle>{t('payment-service-pagarme-settings')}</SectionTitle>
						{status !== 'active' && (
							<Button
								variant="contained"
								type="button"
								onClick={handleActivePaymentServicePagarme}
								disabled={
									isLoadingActivatePaymentServicePagarme ||
									!(
										bankAccount &&
										companyAnnualRevenue &&
										companyLegalRepresentative &&
										transferSettings
									)
								}
								sx={{
									width: 160,
									height: 36.5,
									'&.Mui-disabled': {
										pointerEvents: 'auto'
									}
								}}
							>
								{isLoadingActivatePaymentServicePagarme ? (
									<CircularProgress color="inherit" size={16} />
								) : (
									t('to-activate-service')
								)}
							</Button>
						)}
					</Box>
					{status !== 'active' && status !== 'deactivated' ? (
						<Status.Tag type={status}>
							<Status.Label
								label={t('status-tag-' + status)}
								maxWidth="auto"
								showTooltip={false}
							/>
						</Status.Tag>
					) : (
						<Status.Root
							isButton
							open={openStatusList}
							setAnchorEl={setAnchorElStatusList}
							onClick={() => setOpenStatusList(!openStatusList)}
						>
							<Status.Tag type={status}>
								<Status.Label
									label={t('status-tag-' + status)}
									showTooltip={false}
								/>
								<Status.Icon>
									<ArrowDropDownIcon style={{marginLeft: '4px'}} />
								</Status.Icon>
								<Status.List
									anchorEl={anchorElStatusList}
									open={openStatusList}
									acceptedStatus={[
										{label: t('to-activate'), key: 'active'},
										{label: t('to-deactivate'), key: 'deactivated'}
									].filter(statusMenuItem => statusMenuItem.key !== status)}
									handleClick={handleClickStatusChange}
									handleClose={() => setAnchorElStatusList(null)}
								/>
							</Status.Tag>
						</Status.Root>
					)}

					<Typography variant="body2" mt={4}>
						{parseToHTML(t('alert-info-pagarme-requirements-to-register'))}
					</Typography>

					<Stack spacing={4} mt={4}>
						<Box sx={styles.settingsItem}>
							<Box sx={styles.settingsItemHeader}>
								<Typography variant="h6" component="h3">
									{t('company-info')}
								</Typography>
								{companyAnnualRevenue && (
									<Tooltip title={t('to-edit')} placement="bottom" arrow>
										<IconButton
											sx={styles.icon}
											onClick={() => setOpenFormAnnualRevenue(true)}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
							<Box sx={styles.detailsItemBox}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										justifyContent: 'flex-start',
										gap: 2
									}}
								>
									{companyAnnualRevenue ? (
										<Box>
											<Typography
												variant="subtitle2"
												sx={styles.detailsSubtitle}
											>
												{t('annual-revenue')}
											</Typography>
											<Typography variant="body2" sx={styles.detailsText}>
												{price.full(companyAnnualRevenue)}
											</Typography>
										</Box>
									) : (
										<>
											<Alert severity="info">
												{t('alert-info-annual-revenue')}
											</Alert>
											<Button
												variant="outlined"
												sx={{px: 1.5, py: 0.5, mt: 1}}
												onClick={() => setOpenFormAnnualRevenue(true)}
											>
												{t('to-add')}
											</Button>
										</>
									)}
								</Box>
							</Box>
						</Box>
						<Box sx={styles.settingsItem}>
							<Box sx={styles.settingsItemHeader}>
								<Typography variant="h6" component="h3">
									{t('legal-officer')}
								</Typography>
								{companyLegalRepresentative && (
									<Tooltip title={t('to-edit')} placement="bottom" arrow>
										<IconButton
											sx={styles.icon}
											onClick={() =>
												handleNavigateTo(
													'/payment-services/pagarme/managing-partner'
												)
											}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
							{companyLegalRepresentative ? (
								<Box sx={styles.detailsItemBox}>
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('name')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{companyLegalRepresentative.name}
										</Typography>
									</Box>
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('cpf')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{regex.formatCPF(companyLegalRepresentative.cpf)}
										</Typography>
									</Box>
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('email')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{companyLegalRepresentative.email}
										</Typography>
									</Box>
								</Box>
							) : (
								<>
									<Alert severity="info">
										{parseToHTML(t('legal-officer-alert-info'))}
									</Alert>
									<ButtonLink
										variant="outlined"
										to="/payment-services/pagarme/managing-partner"
										sx={{px: 1.5, py: 0.5, mt: 1}}
									>
										{t('to-add')}
									</ButtonLink>
								</>
							)}
						</Box>
						<Box sx={styles.settingsItem}>
							<Box sx={styles.settingsItemHeader}>
								<Typography variant="h6" component="h3">
									{t('bank-account-details')}
								</Typography>
								{bankAccount && (
									<Tooltip title={t('to-edit')} placement="bottom" arrow>
										<IconButton
											sx={styles.icon}
											onClick={() =>
												handleNavigateTo(
													'/payment-services/pagarme/bank-account'
												)
											}
										>
											<EditIcon />
										</IconButton>
									</Tooltip>
								)}
							</Box>
							{!bankAccount ? (
								<>
									<Alert severity="info">{t('alert-info-bank-account')}</Alert>
									<ButtonLink
										variant="outlined"
										to="/payment-services/pagarme/bank-account"
										sx={{px: 1.5, py: 0.5, mt: 1}}
									>
										{t('to-add')}
									</ButtonLink>
								</>
							) : (
								<Box sx={styles.detailsItemBox}>
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('account-holder')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{company?.legalName.substring(0, 30)}
										</Typography>
									</Box>
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('bank-code')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{bankAccount?.bankCode}
										</Typography>
									</Box>
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('branch-number')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{bankAccount?.branchNumber +
												(bankAccount?.branchCheckDigit
													? `-${bankAccount?.branchCheckDigit}`
													: '')}
										</Typography>
									</Box>
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('account-number')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{bankAccount?.accountNumber +
												'-' +
												bankAccount?.accountCheckDigit}
										</Typography>
									</Box>
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('type')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{t(`account-type-${bankAccount?.accountType}`)}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
						<FormGroup sx={styles.settingsItem}>
							<Box sx={styles.settingsItemHeader}>
								<Typography variant="h6" component="h3">
									{t('automatic-transfer')}
								</Typography>
								<Tooltip title={t('to-edit')} placement="bottom" arrow>
									<IconButton
										sx={styles.icon}
										onClick={() => setOpenFormTransferSettings(true)}
									>
										<EditIcon />
									</IconButton>
								</Tooltip>
							</Box>
							<Box sx={styles.detailsItemBox}>
								<Box>
									<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
										{t('periodicity-of-transfer')}
									</Typography>
									<Typography variant="body2" sx={styles.detailsText}>
										{(() => {
											switch (transferSettings?.transferInterval) {
												case 'Daily':
													return t('periodicity-daily')
												case 'Weekly':
													return t('periodicity-weekly')
												case 'Monthly':
													return t('periodicity-monthly')
												default:
													return ''
											}
										})()}
									</Typography>
								</Box>
								{transferSettings?.transferInterval === 'Monthly' && (
									<Box>
										<Typography variant="subtitle2" sx={styles.detailsSubtitle}>
											{t('transfer-month-day')}
										</Typography>
										<Typography variant="body2" sx={styles.detailsText}>
											{transferSettings?.transferMonthDay}
										</Typography>
									</Box>
								)}
								{transferSettings?.transferInterval === 'Weekly' &&
									transferSettings?.transferWeekDay && (
										<Box>
											<Typography
												variant="subtitle2"
												sx={styles.detailsSubtitle}
											>
												{t('transfer-week-day')}
											</Typography>
											<Typography variant="body2" sx={[styles.detailsText]}>
												{date.getWeekday(
													transferSettings?.transferWeekDay,
													currentLang
												)}
											</Typography>
										</Box>
									)}
							</Box>
						</FormGroup>
					</Stack>
				</Box>
			</DashboardFullSection>
			<Footer />
		</>
	)
}

const styles = {
	checkBoxLabel: {
		'& .MuiTypography-root': {
			fontSize: '0.875rem'
		}
	},
	detailsItemBox: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 5
	},
	detailsSubtitle: (theme: Theme) => ({
		mb: 0.5
	}),
	detailsText: (theme: Theme) => ({
		color: theme.palette.grey[600]
	}),
	icon: (theme: Theme) => ({
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.background.paper,
		borderRadius: '50%',
		border: '1px solid ' + theme.palette.divider,
		'&:hover': {
			borderColor: theme.palette.primary.main
		},
		p: 0.5,
		height: 24,
		width: 24
	}),
	simpleDialogActionsBox: (theme: Theme) => ({
		alignSelf: 'end',
		display: 'flex',
		flexWrap: 'wrap',
		gap: 2,
		justifyContent: 'end',
		mt: 2
	}),
	submitButton: (theme: Theme) => ({
		height: '44px',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		}
	}),
	successButton: (theme: Theme) => ({
		height: '44px',
		backgroundColor: 'success.light',
		color: 'success.contrastText',
		width: '200px',
		[theme.breakpoints.down('sm')]: {
			width: '100%'
		},
		'&:hover': {backgroundColor: 'success.light'}
	}),
	settingsItem: {
		display: 'flex',
		alignItems: 'flex-start',
		flexWrap: 'wrap',
		flexDirection: 'column',
		gap: 2
	},
	settingsItemHeader: {display: 'flex', alignItems: 'center', gap: 1}
}
