import {Dialog} from '../../Dialog'
import {useTranslation} from 'react-i18next'
import {Box} from '@mui/material'

export const LogDialog = ({
	open,
	onClose,
	log
}: {
	open: boolean
	onClose: () => void
	log: any
}) => {
	const {t} = useTranslation()

	return (
		<Dialog.Root open={open}>
			<Dialog.Header>
				<Dialog.Title onClose={onClose}>{t('error-log')}</Dialog.Title>
			</Dialog.Header>
			<Dialog.Body>
				<Box
					sx={{
						backgroundColor: 'grey.100',
						borderRadius: 1,
						px: 2,
						overflow: 'auto'
					}}
				>
					<pre>{JSON.stringify(log, null, 2)}</pre>
				</Box>
			</Dialog.Body>
		</Dialog.Root>
	)
}
