import {useTranslation} from 'react-i18next'
import {Dialog} from '../../../components/Dialog'
import Box from '@mui/material/Box'
import {useQuery} from '@tanstack/react-query'
import {getAnticipationAsaas} from '../../../api/payment-service/asaas'
import {useState} from 'react'
import useAppStates from '../../../stores/useAppStates'
import Card from '@mui/material/Card'
import Typography from '@mui/material/Typography'
import {Status} from '../../../components/Status'
import {date} from '../../../helpers/date'
import Button from '@mui/material/Button'
import {LogDialog} from '../../../components/lists/sales/LogDialog'
import Stack from '@mui/material/Stack'
import {price} from '../../../helpers/price'
import SaleDetailsDialog from '../../../components/lists/sales/DetailsDialog'
import Skeleton from '@mui/material/Skeleton'
import AlertDialog from '../../../components/AlertDialog'

type Anticipation = {
	id: string
	createdAt: string
	processingAt: string
	feeAmount?: number
	netAmount?: number
	status: string
	feesSpecification?: {
		monthlyFee: number
		dailyFee: number
	}
	statusHistory: {
		status: string
		date: string
		log?: string
	}[]
	sale: {
		id: string
		publicId: string
		netAmount: number
		totalAmount: number
		excofyFeeAmount: number
		psFeeAmount: number
		installments: number
	}
}

export const AnticipationDetails = ({
	anticipationId,
	open,
	onClose
}: {
	anticipationId: string
	open: boolean
	onClose: () => void
}) => {
	const {t} = useTranslation()

	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')
	const [anticipation, setAnticipation] = useState<Anticipation | null>(null)
	const [openSaleDetails, setOpenSaleDetails] = useState<boolean>(false)

	const {isFetching} = useQuery(
		['asaas-anticipation'],
		() => getAnticipationAsaas({id: anticipationId}),
		{
			refetchOnWindowFocus: false,
			onSuccess: ({data, responseStatusCode, error}) => {
				if (responseStatusCode === 200 && data) {
					setAnticipation(data.anticipation)
				} else if (responseStatusCode !== 200) {
					onClose()
					setOpenAlert(true)
					setAlertErrorMessage(error?.message || t('error-server-default'))
				}
			},
			onError: error => {
				onClose()
				setOpenAlert(true)
				setAlertErrorMessage(t('error-server-default'))
				console.error(error)
			}
		}
	)

	return (
		<>
			{anticipation?.sale.id ? (
				<SaleDetailsDialog
					id={anticipation.sale.id}
					open={openSaleDetails}
					onClose={() => setOpenSaleDetails(false)}
				/>
			) : null}
			<AlertDialog
				severity="error"
				open={openAlert}
				message={alertErrorMessage}
				onClose={() => setOpenAlert(false)}
			/>
			<Dialog.Root open={open}>
				<Dialog.Header>
					<Dialog.Title onClose={onClose}>
						{t('anticipation-details')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body>
					<Stack direction="column" spacing={2}>
						{isFetching && (
							<>
								<Skeleton variant="rounded" width={452} height={210} />
								<Skeleton variant="rounded" width={452} height={230} />
							</>
						)}
						{anticipation && !isFetching && (
							<>
								<Charge
									totalAmount={anticipation.sale.totalAmount}
									netAmount={anticipation.sale.netAmount}
									excofyFeeAmount={anticipation.sale.excofyFeeAmount}
									psFeeAmount={anticipation.sale.psFeeAmount}
									anticipationFeeAmount={anticipation.feeAmount}
									onClickOpenSale={() => setOpenSaleDetails(true)}
								/>
								<Activities statusHistory={anticipation.statusHistory} />
							</>
						)}
					</Stack>
				</Dialog.Body>
			</Dialog.Root>
		</>
	)
}

function Charge({
	totalAmount,
	netAmount,
	excofyFeeAmount,
	psFeeAmount,
	anticipationFeeAmount,
	onClickOpenSale
}: {
	totalAmount: number
	netAmount: number
	excofyFeeAmount: number
	psFeeAmount: number
	anticipationFeeAmount?: number
	onClickOpenSale?: () => void
}) {
	const {t} = useTranslation()

	return (
		<Card
			variant="outlined"
			sx={theme => ({
				p: 2,
				display: 'flex',
				flexDirection: 'column',
				gap: 1,
				flex: 1,
				[theme.breakpoints.down('sm')]: {
					width: '100%'
				}
			})}
		>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'flex-start',
					justifyContent: 'space-between',
					gap: 2
				}}
			>
				<Typography
					variant="subtitle2"
					sx={theme => ({
						minWidth: 300,
						[theme.breakpoints.down('sm')]: {
							minWidth: '100%'
						}
					})}
				>
					{t('charge-details')}
				</Typography>
				<Button variant="outlined" size="small" onClick={onClickOpenSale}>
					{t('to-view-sale')}
				</Button>
			</Box>
			<Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: 2,
						justifyContent: 'space-between'
					}}
				>
					<Typography>{t('gross-amount')}</Typography>
					<Typography>{price.full(totalAmount)}</Typography>
				</Box>
				<Box
					sx={{
						py: 0.5,
						my: 0.5,
						borderBottomWidth: 1,
						borderBottomStyle: 'solid',
						borderBottomColor: 'divider',
						borderTopWidth: 1,
						borderTopStyle: 'solid',
						borderTopColor: 'divider'
					}}
				>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 2,
							justifyContent: 'space-between'
						}}
					>
						<Typography>{t('ps-fee')}</Typography>
						<Typography>{price.full(psFeeAmount)}</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 2,
							justifyContent: 'space-between'
						}}
					>
						<Typography>{t('excofy-fee')}</Typography>
						<Typography>{price.full(excofyFeeAmount)}</Typography>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 2,
							justifyContent: 'space-between'
						}}
					>
						<Typography>{t('anticipation-fee')}</Typography>
						<Typography>{price.full(anticipationFeeAmount)}</Typography>
					</Box>
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: 2,
						justifyContent: 'space-between'
					}}
				>
					<Typography>{t('net-amount')}</Typography>
					<Typography fontWeight={500}>{price.full(netAmount)}</Typography>
				</Box>
			</Box>
		</Card>
	)
}

function Activities({
	statusHistory
}: {
	statusHistory: {
		status: string
		date: string
		log?: string
	}[]
}) {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	const [openLogDialog, setOpenLogDialog] = useState(false)
	const [log, setLog] = useState<any | null>(null)

	return (
		<>
			{log && (
				<LogDialog
					open={openLogDialog}
					onClose={setOpenLogDialog.bind(null, false)}
					log={log}
				/>
			)}
			<Card
				variant="outlined"
				sx={theme => ({
					p: 2,
					display: 'flex',
					flexDirection: 'column',
					gap: 2,
					flex: 1,
					[theme.breakpoints.down('sm')]: {
						width: '100%'
					}
				})}
			>
				<Typography
					variant="subtitle2"
					sx={theme => ({
						minWidth: 300,
						[theme.breakpoints.down('sm')]: {
							minWidth: '100%'
						}
					})}
				>
					{t('history')}
				</Typography>
				<Box
					sx={theme => ({
						[theme.breakpoints.up('md')]: {
							maxHeight: 'calc(100vh - 220px)',
							overflowY: 'auto',
							'&::-webkit-scrollbar': {
								width: 8
							},
							'&::-webkit-scrollbar-thumb': {
								backgroundColor: 'grey.300',
								borderRadius: 4
							}
						}
					})}
				>
					{statusHistory?.map((item, index) => (
						<Box
							key={'sale-status-history-' + index + '-' + item.date}
							sx={{
								display: 'flex',
								alignItems: 'stretch'
							}}
						>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center'
								}}
							>
								<Status.Circle
									type={item.status}
									fontSize={16}
									style={{zIndex: 1, margin: 0}}
								/>
								{index !== statusHistory.length - 1 && (
									<Box
										sx={{
											flex: 1,
											borderRightWidth: 1,
											borderRightStyle: 'dashed',
											borderColor: 'divider'
										}}
									/>
								)}
							</Box>
							<Box
								sx={{
									pb: 2,
									pl: 1,
									mt: '-4px'
								}}
							>
								<Typography fontWeight={500}>
									{t('status-tag-' + item.status)}
								</Typography>
								<Typography variant="caption" color="text.secondary">
									{date.convertISOToLocation(item.date, currentLang, true)}
								</Typography>
								{item.log && (
									<Button
										variant="outlined"
										onClick={() => {
											setLog(JSON.parse(item.log as string))
											setOpenLogDialog(true)
										}}
										size="small"
										sx={{
											display: 'block',
											mt: 1
										}}
									>
										{t('to-view-error-log')}
									</Button>
								)}
							</Box>
						</Box>
					))}
				</Box>
			</Card>
		</>
	)
}
