import {useTranslation} from 'react-i18next'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Icon from '@mui/material/Icon'
import Skeleton from '@mui/material/Skeleton'
import {Theme} from '@mui/material/styles'
import {MdCreditCard as CreditCardIcon} from 'react-icons/md'
import {MdPix as PixIcon} from 'react-icons/md'
import {BiBarcodeReader as BankSlipIcon} from 'react-icons/bi'
import {BiTransfer as TransferIcon} from 'react-icons/bi'
import useAsaasStates from '../../../stores/useAsaasStates'
import useAppStates from '../../../stores/useAppStates'
import {price} from '../../../helpers/price'
import {regex} from '../../../helpers/regex'
import parseToHTML from 'html-react-parser'
import {PS_FEES_ASAAS} from '../../../config'

export const AsaasFees = () => {
	return (
		<Box sx={styles.detailsContentBox}>
			<PaymentFees />
			<TransferFees />
			<AnticipationFees />
		</Box>
	)
}

const PaymentFees = () => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()
	const {asaasFees} = useAsaasStates()

	return (
		<Box sx={styles.detailsItemBox}>
			<Box>
				<Typography variant="h5" component="h2">
					{t('payment-fees')}
				</Typography>
				<Typography mt={1}>{t('ps-asaas-payment-fees-describe-1')}</Typography>
			</Box>
			<Box sx={styles.detailsItemBox2}>
				<Box sx={styles.detailsItemBox3}>
					<Icon sx={{display: 'flex', fontSize: '1.5rem', mt: '2px'}}>
						<CreditCardIcon />
					</Icon>
					<Box sx={{flex: 1}}>
						<Typography
							variant="subtitle1"
							sx={[styles.detailsSubtitle, styles.paymentFeesTitle]}
						>
							{t('credit-card')}
						</Typography>
						<Box>
							{!asaasFees ? (
								<>
									<Skeleton />
									<Skeleton />
									<Skeleton />
								</>
							) : (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										gap: 1
									}}
								>
									<Box>
										<Box>
											<Typography variant="body2" sx={styles.paymentFeesAmount}>
												{regex.formatPercentage(
													PS_FEES_ASAAS.mdr[1],
													currentLang
												)}
												{' + '}
												{price.full(
													asaasFees?.payment.creditCard.operationValue
												)}{' '}
											</Typography>
											<Typography variant="caption">
												{t('installment-one')}
											</Typography>
										</Box>
										<Box>
											<Typography variant="body2" sx={styles.paymentFeesAmount}>
												{regex.formatPercentage(
													PS_FEES_ASAAS.mdr[2],
													currentLang
												)}
												{' + '}
												{price.full(
													asaasFees?.payment.creditCard.operationValue
												)}{' '}
											</Typography>
											<Typography variant="caption">
												{t('installment-up-to-twelve')}
											</Typography>
										</Box>
										<Box>
											<Typography variant="body2" sx={styles.paymentFeesAmount}>
												{regex.formatPercentage(
													PS_FEES_ASAAS.mdr[7],
													currentLang
												)}
												{' + '}
												{price.full(
													asaasFees?.payment.creditCard.operationValue
												)}{' '}
											</Typography>
											<Typography variant="caption">
												{t('installment-up-to-six')}
											</Typography>
										</Box>
									</Box>
									<Box>
										<Typography variant="caption" color="text.secondary">
											{t('ps-asaas-payment-credit-card-describe-1', {
												daysToReceive:
													asaasFees.payment.creditCard.daysToReceive
											})}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
				<Box sx={styles.detailsItemBox3}>
					<Icon sx={{display: 'flex', fontSize: '1.5rem', mt: '2px'}}>
						<PixIcon />
					</Icon>
					<Box sx={{flex: 1}}>
						<Typography
							variant="subtitle1"
							sx={[styles.detailsSubtitle, styles.paymentFeesTitle]}
						>
							{t('pix')}
						</Typography>
						{!asaasFees ? (
							<Skeleton />
						) : (
							<Typography variant="body2" sx={styles.paymentFeesAmount}>
								{price.full(asaasFees?.payment.pix.fixedFeeValue)}
							</Typography>
						)}
					</Box>
				</Box>
				<Box sx={styles.detailsItemBox3}>
					<Icon sx={{display: 'flex', fontSize: '1.5rem', mt: '2px'}}>
						<BankSlipIcon />
					</Icon>
					<Box sx={{flex: 1}}>
						<Typography
							variant="subtitle1"
							sx={[styles.detailsSubtitle, styles.paymentFeesTitle]}
						>
							{t('ticket-payment')}
						</Typography>
						<Box>
							{!asaasFees ? (
								<Skeleton />
							) : (
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										alignItems: 'flex-start',
										gap: 1
									}}
								>
									<Box>
										<Typography variant="body2" sx={styles.paymentFeesAmount}>
											{price.full(asaasFees.payment.bankSlip.defaultValue)}{' '}
										</Typography>
										<Typography variant="caption">
											{t('per-ticket-payment-paid')}
										</Typography>
									</Box>
									<Box>
										<Typography variant="caption" color="text.secondary">
											{t('ps-asaas-payment-bank-slip-describe-1', {
												daysToReceive: asaasFees.payment.bankSlip.daysToReceive
											})}
										</Typography>
									</Box>
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

const TransferFees = () => {
	const {t} = useTranslation()
	const {asaasFees} = useAsaasStates()

	return (
		<Box sx={styles.detailsItemBox}>
			<Box>
				<Typography variant="h5" component="h2">
					{t('transfer-fees')}
				</Typography>
				<Typography mt={1}>{t('ps-asaas-transfer-fees-describe-1')}</Typography>
			</Box>
			<Box sx={styles.detailsItemBox2}>
				<Box sx={styles.detailsItemBox3}>
					<Icon sx={{display: 'flex', fontSize: '1.5rem', mt: '2px'}}>
						<PixIcon />
					</Icon>
					<Box sx={{flex: 1}}>
						<Typography
							variant="subtitle1"
							sx={[styles.detailsSubtitle, styles.paymentFeesTitle]}
						>
							{t('pix')}
						</Typography>
						{!asaasFees ? (
							<Skeleton />
						) : (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									gap: 1
								}}
							>
								<Typography variant="body2" sx={styles.paymentFeesAmount}>
									{price.full(asaasFees?.transfer.pix.feeValue)}
								</Typography>
								<Typography variant="caption" color="text.secondary">
									{t('ps-asaas-transfer-pix-describe-1', {
										monthlyTransfersWithoutFee:
											asaasFees.transfer.monthlyTransfersWithoutFee
									})}
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
				<Box sx={styles.detailsItemBox3}>
					<Icon sx={{display: 'flex', fontSize: '1.5rem', mt: '2px'}}>
						<TransferIcon />
					</Icon>
					<Box sx={{flex: 1}}>
						<Typography
							variant="subtitle1"
							sx={[styles.detailsSubtitle, styles.paymentFeesTitle]}
						>
							{t('ted')}
						</Typography>
						{!asaasFees ? (
							<Skeleton />
						) : (
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'flex-start',
									gap: 1
								}}
							>
								<Typography variant="body2" sx={styles.paymentFeesAmount}>
									{price.full(asaasFees?.transfer.ted.feeValue)}
								</Typography>
								<Typography variant="caption" color="text.secondary">
									{t('ps-asaas-transfer-ted-describe-1')}
								</Typography>
							</Box>
						)}
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

const AnticipationFees = () => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	return (
		<Box sx={styles.detailsItemBox}>
			<Box>
				<Typography variant="h5" component="h2">
					{t('anticipation-fee')}
				</Typography>
				<Typography mt={1}>{t('automatic-anticipation-describe')}</Typography>
			</Box>
			<Box sx={styles.detailsItemBox2}>
				<Box sx={styles.detailsItemBox3}>
					<Box>
						<Typography
							variant="subtitle1"
							sx={[styles.detailsSubtitle, styles.paymentFeesTitle]}
						>
							{t('fee')}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: 1
							}}
						>
							<Typography variant="body2" sx={styles.paymentFeesAmount}>
								{parseToHTML(
									t('automatic-anticipation-fee', {
										fee: regex.formatPercentage(
											PS_FEES_ASAAS.anticipationMonthlyFee,
											currentLang
										)
									})
								)}
							</Typography>
							<Typography variant="caption" color="text.secondary">
								{t('automatic-anticipation-describe-2')}
							</Typography>
						</Box>
					</Box>
				</Box>
				<Box sx={styles.detailsItemBox3}>
					<Box>
						<Typography
							variant="subtitle1"
							sx={[styles.detailsSubtitle, styles.paymentFeesTitle]}
						>
							{t('anticipation-limits')}
						</Typography>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'flex-start',
								gap: 1
							}}
						>
							<Typography variant="body2" sx={styles.paymentFeesAmount}>
								{price.full(150000)}
							</Typography>
							<Typography variant="caption" color="text.secondary">
								{t('anticipation-limits-describe')}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Box>
		</Box>
	)
}

const styles = {
	detailsContentBox: {
		display: 'flex',
		flexDirection: 'column'
	},
	detailsItemBox: {
		display: 'flex',
		gap: 3,
		flexDirection: 'column',
		marginBottom: 4
	},
	detailsItemBox2: (theme: Theme) => ({
		display: 'flex',
		flexWrap: 'wrap',
		rowGap: 3,
		columnGap: 5,
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column'
		}
	}),
	detailsItemBox3: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 1,
		maxWidth: 300
	},
	detailsSubtitle: {
		mb: 0.5,
		fontWeight: 600
	},
	paymentFeesAmount: {
		display: 'inline',
		fontWeight: 500
	},
	paymentDetailsText: {
		color: 'grey.600'
	},
	paymentFeesTitle: {
		display: 'flex',
		alignItems: 'center',
		gap: '4px'
	}
}
