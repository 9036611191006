import {useState} from 'react'
import {Box, IconButton, Menu, MenuItem, Theme, Tooltip} from '@mui/material'
import {useTheme} from '@mui/material/styles'
import {FaCircle as CircleIcon} from 'react-icons/fa'
import Tag from './typography/Tag'
import {useTranslation} from 'react-i18next'

interface StatusRootProps extends React.HTMLAttributes<HTMLDivElement> {
	children: React.ReactNode
	isButton?: boolean
	open?: boolean
	setAnchorEl?: (value: HTMLElement | null) => void
}

const StatusRoot = ({
	children,
	isButton,
	open,
	setAnchorEl,
	onClick,
	...rest
}: StatusRootProps) => {
	const {t} = useTranslation()
	const [openTooltip, setOpenTooltip] = useState(false)

	return (
		<>
			{isButton ? (
				<Tooltip
					title={t('change-status')}
					placement="bottom"
					arrow
					open={openTooltip}
				>
					<Box
						sx={{
							cursor: 'pointer',
							display: 'inline-flex',
							minHeight: '24px'
						}}
						aria-haspopup="true"
						aria-expanded={open ? 'true' : undefined}
						onClick={event => {
							setAnchorEl && setAnchorEl(event.currentTarget)
							onClick && onClick(event)
							setOpenTooltip(false)
						}}
						onMouseEnter={() => setOpenTooltip(true)}
						onMouseLeave={() => setOpenTooltip(false)}
						{...rest}
					>
						{children}
					</Box>
				</Tooltip>
			) : (
				<Box>{children}</Box>
			)}
		</>
	)
}

interface StatusTagProps {
	children: React.ReactNode
	type: string
	size?: 'small' | 'medium' | 'large'
}

const StatusTag = ({children, type, size}: StatusTagProps) => {
	const theme = useTheme()
	const {color} = getStatusProps(type, theme)

	let py = 0.5
	let px = 0.75

	switch (size) {
		case 'small':
			py = 0
			px = 0.5
			break
		case 'large':
			py = 0.75
			px = 0.75
			break
		default:
			py = 0.5
			px = 0.75
	}

	return (
		<Tag
			color={color}
			sx={{
				py,
				px
			}}
		>
			<StatusCircleIcon type={type} />
			{children}
		</Tag>
	)
}

interface StatusCircleIconProps {
	type: string
	fontSize?: number
	mr?: number | string
	style?: React.CSSProperties
}

const StatusCircleIcon = ({
	type,
	fontSize = 10,
	mr = '4px',
	style
}: StatusCircleIconProps) => {
	const theme = useTheme()
	const {color} = getStatusProps(type, theme)

	return (
		<CircleIcon
			color={color}
			style={{marginRight: mr, ...style}}
			fontSize={fontSize}
		/>
	)
}

interface StatusLabelProps {
	label: React.ReactNode
	maxWidth?: number | string
	showTooltip?: boolean
}

const StatusLabel = ({
	label,
	maxWidth,
	showTooltip = true
}: StatusLabelProps) => {
	return (
		<>
			{showTooltip ? (
				<Tooltip title={label}>
					<span
						style={{
							fontWeight: 600,
							color: 'inherit',
							lineHeight: 'normal',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							maxWidth: maxWidth || 72
						}}
					>
						{label}
					</span>
				</Tooltip>
			) : (
				<span
					style={{
						fontWeight: 600,
						color: 'inherit',
						lineHeight: 'normal',
						whiteSpace: 'nowrap',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
						maxWidth: maxWidth || 72
					}}
				>
					{label}
				</span>
			)}
		</>
	)
}

interface StatusActionProps {
	children?: React.ReactNode
}

const StatusIcon = ({children}: StatusActionProps) => {
	return (
		<IconButton
			size="small"
			sx={{backgroundColor: 'transparent !important', p: 0}}
			color="inherit"
		>
			{children}
		</IconButton>
	)
}

interface StatusListProps {
	anchorEl: HTMLElement | null
	open: boolean
	acceptedStatus: {
		label: string
		key: string
	}[]
	handleClose: () => void
	handleClick: (status: string) => void
}

const StatusList = ({
	anchorEl,
	open,
	acceptedStatus,
	handleClose,
	handleClick
}: StatusListProps) => {
	return (
		<Menu
			anchorEl={anchorEl}
			id="change-status"
			open={open}
			onClose={handleClose}
			onClick={handleClose}
			PaperProps={{
				elevation: 0,
				sx: {
					overflow: 'visible',
					filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
					mt: 1.5,
					'& .MuiAvatar-root': {
						width: 32,
						height: 32,
						ml: -0.5,
						mr: 1
					},
					'&:before': {
						content: '""',
						display: 'block',
						position: 'absolute',
						top: 0,
						right: 14,
						width: 10,
						height: 10,
						bgcolor: 'background.paper',
						transform: 'translateY(-50%) rotate(45deg)',
						zIndex: 0
					}
				}
			}}
			transformOrigin={{horizontal: 'right', vertical: 'top'}}
			anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
		>
			{acceptedStatus.map(item => (
				<MenuItem
					key={item.key}
					onClick={() => {
						handleClick(item.key)
						handleClose()
					}}
				>
					{item.label}
				</MenuItem>
			))}
		</Menu>
	)
}

export const getStatusProps = (status: string, theme: Theme) => {
	if (
		[
			'on',
			'active',
			'paid',
			'completed',
			'sent',
			'transferred',
			'approved',
			'processed'
		].includes(status)
	) {
		return {color: theme.palette.success.main}
	}

	if (
		['paused', 'pending', 'pending-settings', 'pending-setup'].includes(status)
	) {
		return {color: theme.palette.warning.main}
	}

	if (['archived', 'interrupted', 'off', 'deactivated'].includes(status)) {
		return {color: theme.palette.grey[600]}
	}

	if (['all'].includes(status)) {
		return {color: theme.palette.common.black}
	}

	if (
		[
			'open',
			'waiting',
			'pending-activation',
			'in-review',
			'processing',
			'awaiting-approval',
			'not-sent'
		].includes(status)
	) {
		return {color: '#b99905'}
	}

	if (
		[
			'canceled',
			'failed',
			'declined',
			'charged-back',
			'rejected',
			'denied'
		].includes(status)
	) {
		return {color: theme.palette.error.main}
	}

	if (
		[
			'refund-cancelled',
			'refunded',
			'processing-refund',
			'awaiting-customer-data-for-refund',
			'partial-refunded'
		].includes(status)
	) {
		return {color: '#9745FE'}
	}

	return {color: theme.palette.grey[600]}
}

export const Status = {
	Root: StatusRoot,
	Tag: StatusTag,
	Label: StatusLabel,
	Icon: StatusIcon,
	Circle: StatusCircleIcon,
	List: StatusList
}
