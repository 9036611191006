import {
	Box,
	Button,
	FormControl,
	FormHelperText,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {price} from '../helpers/price'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {regex} from '../helpers/regex'
import {AsaasLogo} from './logos/AsaasLogo'
import {PagarmeLogo} from './logos/PagarmeLogo'
import {Status} from './Status'
import useAuthStates from '../stores/useAuthStates'
import {EXCOFY_FEE, PS_FEES} from '../config'
import useAppStates from '../stores/useAppStates'
import {useCallback, useMemo, useState} from 'react'
import {Dialog} from './Dialog'
import {
	calculateSaleWithPix,
	calculateSaleWithBankSlip,
	calculateSaleWithCreditCard
} from '../helpers/calculateSale'
import InfoPopover from './InfoPopover'

type Installment = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12

const useValidationSchema = () => {
	const {t} = useTranslation()

	const validationSchema = yup.object({
		paymentService: yup
			.string()
			.oneOf(['asaas', 'pagarme'], t('error-payment-service-required'))
			.required(t('error-payment-service-required')),
		paymentMethod: yup
			.string()
			.oneOf(
				['credit-card', 'boleto', 'pix'],
				t('error-payment-method-required')
			)
			.required(t('error-payment-method-required')),
		amount: yup
			.string()
			.transform(value => String(regex.parseBRLCurrencyToFloat(value)))
			.required(t('error-amount-required')),
		installments: yup
			.string()
			.matches(/^\d{1,2}$/, t('error-installments-invalid'))
	})

	return validationSchema
}

export const SaleSimulate = ({
	open,
	onClose,
	initialAmount,
	initialAnticipationEnabled,
	initialPassAnticipationFeesToCustomer,
	initialPassCreditCardFeesToCustomer,
	initialPassExcofyFee,
	maxInstallments,
	defaultInstallments,
	additionalProfit
}: {
	open: boolean
	onClose: () => void
	initialAmount?: number
	initialAnticipationEnabled?: boolean
	initialPassAnticipationFeesToCustomer?: boolean
	initialPassCreditCardFeesToCustomer?: boolean
	initialPassExcofyFee?: boolean
	maxInstallments: number
	defaultInstallments: number
	additionalProfit?: {
		enabled: boolean
		percentageOfGrossAmount?: number
		monthlyInterestRate?: number
	}
}) => {
	const {t} = useTranslation()
	const {paymentService} = useAuthStates()
	const {currentLang} = useAppStates()
	const validationSchema = useValidationSchema()

	const [openSimulationResult, setOpenSimulationResult] = useState(false)
	const [simulationResultWithCreditCard, setSimulationResultWithCreditCard] =
		useState<{
			grossAmount: number
			netAmount: number
			installmentAmount: number
		} | null>(null)
	const [
		simulationResultWithPixOrBankSlip,
		setSimulationResultWithPixOrBankSlip
	] = useState<{grossAmount: number; netAmount: number}>()

	const formik = useFormik({
		initialValues: {
			paymentService:
				paymentService?.activeService || ('asaas' as 'asaas' | 'pagarme'),
			paymentMethod: 'credit-card' as 'credit-card' | 'bank-slip' | 'pix',
			amount: initialAmount ? price.decimal(initialAmount) : price.decimal(100),
			installments: defaultInstallments as Installment,
			anticipationEnabled: Boolean(initialAnticipationEnabled),
			passAnticipationFeesToCustomer: Boolean(
				initialPassAnticipationFeesToCustomer
			),
			passCreditCardFeesToCustomer: Boolean(
				initialPassCreditCardFeesToCustomer
			),
			passExcofyFee: Boolean(initialPassExcofyFee)
		},
		enableReinitialize: true,
		validationSchema,
		onSubmit: values => {}
	})

	const getInstallmentValue = useCallback(
		(numInstallments: Installment) => {
			const psFees = PS_FEES[formik.values.paymentService]

			const anticipationEnabled =
				formik.values.paymentService === 'asaas'
					? formik.values.anticipationEnabled
					: false

			return price.full(
				calculateSaleWithCreditCard({
					paymentService: formik.values.paymentService,
					amount: regex.parseBRLCurrencyToFloat(formik.values.amount),
					numInstallments,
					fees: {
						creditCard: {
							anticipationEnabled,
							passOnAnticipationFee:
								formik.values.passAnticipationFeesToCustomer,
							passOnProcessingFee: formik.values.passCreditCardFeesToCustomer,
							anticipationDailyFee: psFees.anticipationDailyFee,
							anticipationMonthlyFee: psFees.anticipationMonthlyFee,
							anticipationDays: psFees.daysToAnticipation,
							processingFixedFee: psFees.fixedFee,
							processingPercentageFee: psFees.mdr[numInstallments]
						},
						excofy: {
							passOnFee: formik.values.passExcofyFee,
							percentageFee: EXCOFY_FEE
						},
						seller: {
							enabled: Boolean(additionalProfit?.enabled),
							percentageFee: additionalProfit?.percentageOfGrossAmount
								? additionalProfit?.percentageOfGrossAmount / 100
								: 0,
							monthlyInterestRate: additionalProfit?.monthlyInterestRate
								? additionalProfit?.monthlyInterestRate / 100
								: 0
						}
					}
				}).installmentAmount
			)
		},
		[formik, additionalProfit]
	)

	const handleSimulateSale = useCallback(() => {
		const psFees = PS_FEES[formik.values.paymentService]

		if (formik.values.paymentMethod === 'credit-card') {
			const result = calculateSaleWithCreditCard({
				paymentService: formik.values.paymentService,
				amount: regex.parseBRLCurrencyToFloat(formik.values.amount),
				numInstallments: formik.values.installments,
				fees: {
					creditCard: {
						anticipationEnabled: formik.values.anticipationEnabled,
						passOnProcessingFee: formik.values.passCreditCardFeesToCustomer,
						passOnAnticipationFee: formik.values.passAnticipationFeesToCustomer,
						anticipationDailyFee: psFees.anticipationDailyFee,
						anticipationMonthlyFee: psFees.anticipationMonthlyFee,
						anticipationDays: psFees.daysToAnticipation,
						processingFixedFee: psFees.fixedFee,
						processingPercentageFee: psFees.mdr[formik.values.installments]
					},
					excofy: {
						passOnFee: formik.values.passExcofyFee,
						percentageFee: EXCOFY_FEE
					},
					seller: {
						enabled: Boolean(additionalProfit?.enabled),
						percentageFee: additionalProfit?.percentageOfGrossAmount
							? additionalProfit?.percentageOfGrossAmount / 100
							: 0,
						monthlyInterestRate: additionalProfit?.monthlyInterestRate
							? additionalProfit?.monthlyInterestRate / 100
							: 0
					}
				}
			})

			setSimulationResultWithCreditCard(result)
		} else if (formik.values.paymentMethod === 'pix') {
			const result = calculateSaleWithPix({
				amount: regex.parseBRLCurrencyToFloat(formik.values.amount),
				paymentService: formik.values.paymentService,
				fees: {
					pix: psFees.pix,
					excofy: {
						percentageFee: EXCOFY_FEE
					}
				}
			})

			setSimulationResultWithPixOrBankSlip(result)
		} else {
			const result = calculateSaleWithBankSlip({
				amount: regex.parseBRLCurrencyToFloat(formik.values.amount),
				fees: {
					bankSlip: psFees.boleto,
					excofy: {
						percentageFee: EXCOFY_FEE
					}
				}
			})

			setSimulationResultWithPixOrBankSlip(result)
		}

		setOpenSimulationResult(true)
	}, [formik, additionalProfit])

	const installments = useMemo(
		() =>
			[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
				.filter(installmentNumber => installmentNumber <= maxInstallments)
				.map(installmentNumber => ({
					installmentNumber,
					installmentAmount: getInstallmentValue(
						installmentNumber as Installment
					)
				})),
		[maxInstallments, getInstallmentValue]
	)

	return (
		<>
			{openSimulationResult && (
				<Dialog.Root open={openSimulationResult}>
					<Dialog.Header>
						<Dialog.Title onClose={() => setOpenSimulationResult(false)}>
							{t('simulation-result')}
						</Dialog.Title>
					</Dialog.Header>
					<Dialog.Body sx={{pt: '16px !important'}}>
						<Box
							sx={{
								display: 'flex',
								flexDirection: 'column',
								rowGap: 1
							}}
						>
							<Box>
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										gap: 0.5,
										alignItems: 'center',
										justifyContent: 'space-between',
										borderBottom: '1px solid',
										borderBottomColor: 'divider',
										pb: 1
									}}
								>
									<Typography variant="body1">{t('gross-amount')}</Typography>
									<Box sx={{display: 'flex', alignItems: 'center', gap: 0.5}}>
										{formik.values.paymentMethod === 'credit-card' &&
											simulationResultWithCreditCard && (
												<>
													<Typography variant="body2" color="text.secondary">
														{'('}
														{formik.values.installments}
														{'x '}
														{t('of')}{' '}
														{price.full(
															simulationResultWithCreditCard.installmentAmount
														)}
														{')'}
													</Typography>
													<Typography variant="body1" fontWeight={500}>
														{price.full(
															simulationResultWithCreditCard.grossAmount
														)}
													</Typography>
												</>
											)}
										<Typography variant="body1" fontWeight={500}>
											{(formik.values.paymentMethod === 'pix' ||
												formik.values.paymentMethod === 'bank-slip') &&
												simulationResultWithPixOrBankSlip &&
												price.full(
													simulationResultWithPixOrBankSlip.grossAmount
												)}
										</Typography>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										flexWrap: 'wrap',
										gap: 0.5,
										alignItems: 'center',
										justifyContent: 'space-between',
										py: 1
									}}
								>
									<Typography variant="body1">{t('net-amount')}</Typography>
									<Box sx={{display: 'inline-flex', alignItems: 'center'}}>
										{formik.values.paymentMethod === 'credit-card' && (
											<InfoPopover>
												{t('net-amount-estimated-info')}
											</InfoPopover>
										)}
										<Typography variant="body1" fontWeight={500}>
											{formik.values.paymentMethod === 'credit-card' &&
												simulationResultWithCreditCard &&
												price.full(simulationResultWithCreditCard.netAmount)}
											{(formik.values.paymentMethod === 'pix' ||
												formik.values.paymentMethod === 'bank-slip') &&
												simulationResultWithPixOrBankSlip &&
												price.full(simulationResultWithPixOrBankSlip.netAmount)}
										</Typography>
									</Box>
								</Box>
							</Box>
							<Box>
								<Typography component="h2" variant="subtitle1" mb={1} mt={1}>
									{t('fees-details')}
								</Typography>
								{formik.values.paymentMethod === 'bank-slip' &&
									simulationResultWithPixOrBankSlip && (
										<Typography variant="body2">
											{t('sale-simulation-result-describe-pix-bank-slip-01', {
												psFee: price.full(
													PS_FEES[formik.values.paymentService].boleto
												),
												excofyFee: regex.formatPercentage(
													EXCOFY_FEE,
													currentLang
												)
											})}
										</Typography>
									)}
								{formik.values.paymentMethod === 'pix' &&
									simulationResultWithPixOrBankSlip && (
										<Typography variant="body2">
											{t('sale-simulation-result-describe-pix-bank-slip-01', {
												psFee:
													formik.values.paymentService === 'asaas'
														? price.full(
																PS_FEES[formik.values.paymentService].pix
														  )
														: regex.formatPercentage(
																PS_FEES[formik.values.paymentService].pix / 100,
																currentLang
														  ),
												excofyFee: regex.formatPercentage(
													EXCOFY_FEE,
													currentLang
												)
											})}
										</Typography>
									)}
								{formik.values.paymentMethod === 'credit-card' &&
									simulationResultWithCreditCard && (
										<Typography variant="body2">
											{formik.values.paymentMethod === 'credit-card' &&
											(!formik.values.anticipationEnabled ||
												formik.values.paymentService === 'pagarme')
												? t('sale-simulation-result-describe-credit-card-01', {
														processingFee:
															regex.formatPercentage(
																PS_FEES[formik.values.paymentService].mdr[
																	formik.values.installments
																],
																currentLang
															) +
															' + ' +
															price.full(
																PS_FEES[formik.values.paymentService].fixedFee
															),
														excofyFee: regex.formatPercentage(
															EXCOFY_FEE,
															currentLang
														)
												  })
												: null}
											{formik.values.paymentMethod === 'credit-card' &&
											formik.values.anticipationEnabled &&
											formik.values.paymentService !== 'pagarme'
												? t('sale-simulation-result-describe-credit-card-02', {
														processingFee:
															regex.formatPercentage(
																PS_FEES[formik.values.paymentService].mdr[
																	formik.values.installments
																],
																currentLang
															) +
															' + ' +
															price.full(
																PS_FEES[formik.values.paymentService].fixedFee
															),
														anticipationFee: regex.formatPercentage(
															PS_FEES[formik.values.paymentService]
																.anticipationMonthlyFee,
															currentLang
														),
														excofyFee: regex.formatPercentage(
															EXCOFY_FEE,
															currentLang
														)
												  })
												: null}
										</Typography>
									)}
							</Box>
						</Box>
					</Dialog.Body>
				</Dialog.Root>
			)}
			<Dialog.Root open={open}>
				<Dialog.Header>
					<Dialog.Title onClose={onClose}>{t('to-simulate-sale')}</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body>
					<Box>
						<Stack spacing={4}>
							<FormControl
								size="small"
								sx={{
									minWidth: 232
								}}
							>
								<InputLabel id="select-payment-service-label">
									{t('payment-service')}
								</InputLabel>
								<Select
									labelId="select-payment-service-label"
									id="select-payment-service"
									name="paymentService"
									variant="outlined"
									error={
										formik.touched.paymentService &&
										Boolean(formik.errors.paymentService)
									}
									value={formik.values.paymentService}
									label={t('payment-service')}
									onChange={e => {
										formik.handleChange(e)
									}}
								>
									<MenuItem value="asaas">
										<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
											<AsaasLogo height={24} />
											{paymentService?.activeService === 'asaas' && (
												<Status.Tag type="active">
													<Status.Label
														label={t('active')}
														maxWidth="auto"
														showTooltip={false}
													/>
												</Status.Tag>
											)}
										</Box>
									</MenuItem>
									<MenuItem value="pagarme">
										<Box sx={{display: 'flex', alignItems: 'center', gap: 1}}>
											<PagarmeLogo height={24} />
											{paymentService?.activeService === 'pagarme' && (
												<Status.Tag type="active">
													<Status.Label
														label={t('active')}
														maxWidth="auto"
														showTooltip={false}
													/>
												</Status.Tag>
											)}
										</Box>
									</MenuItem>
								</Select>
							</FormControl>
							<Box>
								<FormControl
									size="small"
									sx={{
										width: '100%'
									}}
								>
									<InputLabel id="select-payment-method-label">
										{t('payment-method')}
									</InputLabel>
									<Select
										labelId="select-payment-method-label"
										id="select-payment-method"
										name="paymentMethod"
										variant="outlined"
										error={
											formik.touched.paymentMethod &&
											Boolean(formik.errors.paymentMethod)
										}
										value={formik.values.paymentMethod}
										label={t('payment-method')}
										onChange={formik.handleChange}
									>
										<MenuItem value="credit-card">{t('credit-card')}</MenuItem>
										<MenuItem value="pix">{t('pix')}</MenuItem>
										<MenuItem value="bank-slip">{t('boleto')}</MenuItem>
									</Select>
								</FormControl>
							</Box>
							<TextField
								name="amount"
								label={t('sale-amount')}
								value={formik.values.amount}
								onChange={event => {
									event.target.value = regex.formatBRLCurrency(
										event.target.value
									)
									formik.handleChange(event)
								}}
								onBlur={formik.handleBlur}
								helperText={formik.touched.amount && formik.errors.amount}
								error={formik.touched.amount && Boolean(formik.errors.amount)}
								variant="outlined"
								size="small"
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">{'R$'}</InputAdornment>
									)
								}}
							/>
							{formik.values.paymentMethod === 'credit-card' && (
								<FormControl size="small">
									<InputLabel id="select-installments-label">
										{t('installments-quantity')}
									</InputLabel>
									<Select
										labelId="select-installments-label"
										id="select-installments"
										name="installments"
										variant="outlined"
										value={formik.values.installments}
										label={t('installments-quantity')}
										onChange={formik.handleChange}
										renderValue={installment => (
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													gap: '4px',
													width: '100%'
												}}
											>
												<Typography>
													{installment}
													{'x '}
													{t('of')}{' '}
												</Typography>
												<Typography>
													{getInstallmentValue(installment)}
												</Typography>
											</Box>
										)}
									>
										{installments.map(item => (
											<MenuItem
												key={'installment-' + item.installmentNumber}
												value={item.installmentNumber}
												sx={{
													display: 'flex',
													alignItems: 'center',
													justifyContent: 'space-between'
												}}
											>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'center',
														gap: 2,
														justifyContent: 'space-between',
														width: '100%'
													}}
												>
													<Box
														sx={{
															display: 'flex',
															alignItems: 'center',
															gap: 0.5
														}}
													>
														<Typography>
															{item.installmentNumber}x {t('of')}
														</Typography>
														<Typography sx={{fontWeight: 500}}>
															{item.installmentAmount}
														</Typography>
													</Box>
													{!formik.values.passCreditCardFeesToCustomer &&
														!formik.values.passAnticipationFeesToCustomer && (
															<Typography variant="caption">
																{t('interest-free')}
															</Typography>
														)}
												</Box>
											</MenuItem>
										))}
									</Select>
									<FormHelperText>
										{t('helper-text-credit-card-fees', {
											percentageFee: regex.formatPercentage(
												PS_FEES[formik.values.paymentService].mdr[
													formik.values.installments
												],
												currentLang
											),
											fixedFee: price.full(
												PS_FEES[formik.values.paymentService].fixedFee
											)
										})}
									</FormHelperText>
								</FormControl>
							)}
						</Stack>
					</Box>
				</Dialog.Body>
				<Dialog.Footer>
					<Button
						size="large"
						variant="contained"
						type="submit"
						sx={{height: 40}}
						onClick={handleSimulateSale}
						fullWidth
					>
						{t('to-simulate')}
					</Button>
				</Dialog.Footer>
			</Dialog.Root>
		</>
	)
}
