type Fees = {
	creditCard: {
		anticipationEnabled: boolean
		passOnProcessingFee: boolean
		passOnAnticipationFee: boolean
		anticipationDailyFee: number
		anticipationMonthlyFee: number
		anticipationDays: number
		processingFixedFee: number
		processingPercentageFee: number
	}
	excofy: {passOnFee: boolean; percentageFee: number}
	seller: {
		enabled: boolean
		percentageFee: number
		monthlyInterestRate: number
	}
}

type PaymentService = 'pagarme' | 'asaas'

interface Result {
	installmentNumber: number
	grossAmount: number
	netAmount: number
	totalDiscounts: number
	discounts: {
		creditCard: {
			processing: number
			anticipation?: number
		}
		splits: {
			excofy: number
		}
	}
	calcDetailsProcessingFees: {
		baseAmount: number
		netAmount: number
		mdrFeePercentage: number
		fixedFeeAmount: number
		totalFeesAmount: number
	}
	calcDetailsAnticipationFees?: {
		baseAmount: number
		netAmount: number
		anticipationDailyFeePercentage: number
		daysToAnticipation: number
		totalFeesAmount: number
	}
}

const truncateDecimal = (value: number) => {
	return Math.floor(value * 100) / 100
}

const roundDecimal = (value: number) => {
	return parseFloat(value.toFixed(2))
}

export const calculateSaleWithPix = ({
	amount,
	paymentService,
	fees
}: {
	amount: number
	paymentService: PaymentService
	fees: {
		pix: number
		excofy: {percentageFee: number}
	}
}): {
	grossAmount: number
	netAmount: number
} => {
	let feeAmount: number

	if (paymentService === 'asaas') {
		feeAmount = fees.pix
	} else {
		feeAmount = roundDecimal(amount * (fees.pix / 10000))
	}

	const excofyTotalAmount = roundDecimal(amount * fees.excofy.percentageFee)

	return {
		grossAmount: amount,
		netAmount: amount - feeAmount - excofyTotalAmount
	}
}

export const calculateSaleWithBankSlip = ({
	amount,
	fees
}: {
	amount: number
	fees: {
		bankSlip: number
		excofy: {percentageFee: number}
	}
}): {
	grossAmount: number
	netAmount: number
} => {
	const feeAmount = fees.bankSlip

	const excofyTotalAmount = roundDecimal(amount * fees.excofy.percentageFee)

	return {
		grossAmount: amount,
		netAmount: amount - feeAmount - excofyTotalAmount
	}
}

export const calculateSaleWithCreditCard = ({
	amount,
	numInstallments,
	paymentService,
	fees
}: {
	amount: number
	numInstallments: number
	paymentService: PaymentService
	fees: Fees
}): {
	grossAmount: number
	netAmount: number
	installmentAmount: number
} => {
	// Soma Repasse de Taxas Percentuais
	let totalPercentageRatesPassedOn = 0
	if (fees.seller.enabled && fees.seller.percentageFee > 0) {
		totalPercentageRatesPassedOn += fees.seller.percentageFee
	}
	if (fees.creditCard.passOnProcessingFee) {
		totalPercentageRatesPassedOn += fees.creditCard.processingPercentageFee
	}
	if (fees.excofy.passOnFee) {
		totalPercentageRatesPassedOn += fees.excofy.percentageFee
	}

	// Soma Repasse de Juros ao Mês
	let totalMonthlyInterestRatePassedOn = 0
	if (fees.seller.enabled && fees.seller.monthlyInterestRate > 0) {
		totalMonthlyInterestRatePassedOn += fees.seller.monthlyInterestRate
	}
	if (fees.creditCard.passOnAnticipationFee) {
		totalMonthlyInterestRatePassedOn += fees.creditCard.anticipationMonthlyFee
	}

	// Soma Repasse de Taxa Fixa
	let totalFixedFee = fees.creditCard.processingFixedFee
	if (paymentService === 'asaas') {
		totalFixedFee =
			truncateDecimal(fees.creditCard.processingFixedFee / numInstallments) *
			numInstallments // Calcula Taxa Fixa Aplicada
	}
	let totalFixedFeePassedOn = 0
	if (fees.creditCard.passOnProcessingFee) {
		totalFixedFeePassedOn += totalFixedFee
	}

	// Inicializa Valor Bruto da Venda
	let totalGrossAmount = amount

	if (numInstallments > 1 && totalMonthlyInterestRatePassedOn > 0) {
		// Calcula Valor Bruto da Venda com Repasse de Taxa de Juros ao Mês
		const calculateReceivedAmount = (currentGrossAmount: number) => {
			let discountPercentageFee = fees.creditCard.processingPercentageFee

			if (fees.seller.enabled) {
				discountPercentageFee += fees.seller.percentageFee
			}
			if (fees.excofy.passOnFee) {
				discountPercentageFee += fees.excofy.percentageFee
			}

			let remainingAmount =
				currentGrossAmount -
				totalFixedFee - // Subtrair a taxa fixa
				roundDecimal(currentGrossAmount * discountPercentageFee) // Subtrair a taxa percentual

			const installments = generateInstallments(
				remainingAmount,
				numInstallments
			)

			let totalInstallmentsAmount = installments[0] // Primeira parcela não tem antecipação

			const dailyInterestRate = totalMonthlyInterestRatePassedOn / 30

			for (let i = 1; i < numInstallments; i++) {
				const newInstallmentAmount =
					installments[i] -
					calculateInterestRate({
						amount: installments[i],
						numInstallment: i,
						daysRange: fees.creditCard.anticipationDays,
						dailyFee: dailyInterestRate
					})

				totalInstallmentsAmount += newInstallmentAmount
			}

			return totalInstallmentsAmount
		}

		let expectedNetAmount = amount

		if (!fees.creditCard.passOnProcessingFee) {
			expectedNetAmount =
				amount -
				totalFixedFee -
				amount * fees.creditCard.processingPercentageFee
		}

		if (!fees.excofy.passOnFee) {
			expectedNetAmount = expectedNetAmount - amount * fees.excofy.percentageFee
		}

		let grossAmountMin = expectedNetAmount
		let grossAmountMax = expectedNetAmount * 1.5
		let grossAmount = (grossAmountMin + grossAmountMax) / 2

		const precision = 0.01

		while (grossAmountMax - grossAmountMin > precision) {
			const amountReceived = calculateReceivedAmount(grossAmount)

			if (amountReceived < expectedNetAmount) {
				grossAmountMin = grossAmount
			} else {
				grossAmountMax = grossAmount
			}

			grossAmount = (grossAmountMin + grossAmountMax) / 2
		}

		totalGrossAmount = roundDecimal(grossAmount)
	} else if (numInstallments > 1 && totalPercentageRatesPassedOn > 0) {
		// Calcula Valor Bruto com Repasse de Taxa Percentual
		totalGrossAmount = roundDecimal(
			(amount + totalFixedFeePassedOn) / (1 - totalPercentageRatesPassedOn)
		)
	}

	const calcDetails = generateCalcDetails({
		totalGrossAmount: roundDecimal(totalGrossAmount),
		numInstallments,
		paymentService,
		fees
	})

	// console.info('Calculation Details', calcDetails)

	return {
		grossAmount: totalGrossAmount,
		netAmount: calcDetails.totalNetAmount,
		installmentAmount: calcDetails.installmentAmount
	}
}

const calculateInterestRate = ({
	amount,
	numInstallment,
	daysRange,
	dailyFee
}: {
	amount: number
	numInstallment: number
	daysRange: number
	dailyFee: number
}) => {
	const countDays = daysRange * numInstallment
	return amount * dailyFee * countDays
}

const generateCalcDetails = ({
	totalGrossAmount,
	numInstallments,
	paymentService,
	fees
}: {
	totalGrossAmount: number
	numInstallments: number
	paymentService: PaymentService
	fees: Fees
}) => {
	const processingPercentageFee = fees.creditCard.processingPercentageFee

	let totalFixedFee = fees.creditCard.processingFixedFee
	if (paymentService === 'asaas') {
		totalFixedFee =
			truncateDecimal(fees.creditCard.processingFixedFee / numInstallments) *
			numInstallments // Calcula Taxa Fixa Aplicada
	}

	const dailyInterestRate = fees.creditCard.anticipationDailyFee

	const totalSplitAmount = roundDecimal(
		totalGrossAmount * fees.excofy.percentageFee
	)

	const installments = generateInstallments(totalGrossAmount, numInstallments)
	const excofyInstallmentSplits = generateInstallments(
		totalSplitAmount,
		numInstallments
	)

	const calcDetails = installments.map((installmentGrossAmount, i) => {
		const installmentFixedFee =
			numInstallments === 1
				? totalFixedFee
				: truncateDecimal(totalFixedFee / numInstallments)

		const totalFeesAmount =
			truncateDecimal(installmentGrossAmount * processingPercentageFee) +
			installmentFixedFee

		let netAmount =
			installmentGrossAmount - totalFeesAmount - excofyInstallmentSplits[i]

		const installmentNumber = i + 1

		const result: Result = {
			installmentNumber,
			grossAmount: installmentGrossAmount,
			netAmount,
			totalDiscounts: totalFeesAmount + excofyInstallmentSplits[i],
			discounts: {
				creditCard: {
					processing: totalFeesAmount
				},
				splits: {
					excofy: excofyInstallmentSplits[i]
				}
			},
			calcDetailsProcessingFees: {
				baseAmount: installmentGrossAmount,
				netAmount,
				mdrFeePercentage: processingPercentageFee,
				fixedFeeAmount: installmentFixedFee,
				totalFeesAmount: truncateDecimal(totalFeesAmount)
			}
		}

		if (installmentNumber > 1 && fees.creditCard.anticipationEnabled) {
			const anticipationDays =
				(installmentNumber - 1) * fees.creditCard.anticipationDays

			const anticipationFee = roundDecimal(
				netAmount * fees.creditCard.anticipationDailyFee * anticipationDays
			) //

			result.netAmount = netAmount - anticipationFee
			result.discounts.creditCard.anticipation = anticipationFee
			result.calcDetailsAnticipationFees = {
				baseAmount: netAmount,
				netAmount: netAmount - anticipationFee,
				anticipationDailyFeePercentage: dailyInterestRate,
				daysToAnticipation: anticipationDays,
				totalFeesAmount: anticipationFee
			}
		}

		return result
	})

	const totalNetAmount = truncateDecimal(
		calcDetails.reduce((acc, item) => acc + item.netAmount, 0)
	)

	const sumExcofySplit = roundDecimal(
		calcDetails.reduce((acc, item) => acc + item.discounts.splits.excofy, 0)
	)

	const sumProcessingFee = roundDecimal(
		calcDetails.reduce(
			(acc, item) => acc + item.discounts.creditCard.processing,
			0
		)
	)

	const sumAnticipationFee = roundDecimal(
		calcDetails.reduce(
			(acc, item) => acc + (item.discounts.creditCard?.anticipation || 0),
			0
		)
	)

	return {
		numInstallments,
		installmentAmount: calcDetails[0].grossAmount,
		totalNetAmount,
		totalGrossAmount,
		discounts: {
			creditCard: {
				processing: {
					percentage: fees.creditCard.processingPercentageFee,
					fixed: totalFixedFee,
					amount: sumProcessingFee
				},
				anticipation: {
					percentage: fees.creditCard.anticipationMonthlyFee,
					amount: sumAnticipationFee
				}
			},
			splits: {
				excofy: {
					percentage: fees.excofy.percentageFee,
					amount: sumExcofySplit
				}
			},
			profit: {
				percentage: fees.seller?.percentageFee || 0,
				monthlyInterestRate: fees.seller?.monthlyInterestRate || 0
			}
		},
		installmentsDetails: calcDetails
	}
}

const generateInstallments = (amount: number, numInstallments: number) => {
	if (numInstallments === 1) {
		return [amount]
	}

	const initialInstallments = truncateDecimal(amount / numInstallments)
	const countInitialInstallments = truncateDecimal(
		initialInstallments * (numInstallments - 1)
	)
	const lastInstallment = truncateDecimal(amount - countInitialInstallments)

	return Array.from({length: numInstallments}).map((_, i) => {
		if (i === numInstallments - 1) {
			return lastInstallment
		} else {
			return initialInstallments
		}
	})
}
