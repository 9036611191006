import {useTranslation} from 'react-i18next'
import useAppStates from '../../../stores/useAppStates'
import useAsaasStates from '../../../stores/useAsaasStates'
import {useCallback, useEffect, useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {fetchAnticipationAsaas} from '../../../api/payment-service/asaas'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import TableBody from '@mui/material/TableBody'
import {date} from '../../../helpers/date'
import {price} from '../../../helpers/price'
import {Status} from '../../../components/Status'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import AlertMessage from '../../../components/AlertMessage'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {useQuery} from '@tanstack/react-query'
import {getAnticipationConfigAsaas} from '../../../api/payment-service/asaas'
import Skeleton from '@mui/material/Skeleton'
import AlertDialog from '../../../components/AlertDialog'
import Stack from '@mui/material/Stack'
import InfoPopover from '../../../components/InfoPopover'
import parseToHTML from 'html-react-parser'
import {AnticipationDetails} from './AnticipationDetails'

export const AsaasAnticipation = () => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	const [openAlertError, setOpenAlertError] = useState(false)
	const [errorMessage, setErrorMessage] = useState('')
	const [limits, setLimits] = useState({
		total: 0,
		available: 0
	})

	const {setAsaasAnticipations, asaasAnticipations, addAsaasAnticipations} =
		useAsaasStates()
	const limit = 20

	const [offset, setOffset] = useState<number>(0)
	const [hasMore, setHasMore] = useState<boolean>(false)
	const [firstLoad, setFirstLoad] = useState(true)

	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertErrorMessage, setAlertErrorMessage] = useState<string>('')
	const [anticipationId, setAnticipationId] = useState<string | null>(null)
	const [openDetails, setOpenDetails] = useState(false)

	const handleLoadMore = useCallback(() => {
		setOffset(offset + limit)
	}, [offset, limit])

	const {mutate: mutateFetch, isLoading: isLoadingAnticipations} = useMutation(
		fetchAnticipationAsaas,
		{
			networkMode: 'always'
		}
	)

	const fetchAnticipations = useCallback(async () => {
		setOpenAlert(false)
		mutateFetch(
			{offset, limit},
			{
				onSuccess: ({data, responseStatusCode, error}) => {
					if (responseStatusCode === 200 && data) {
						if (offset === 0) {
							setAsaasAnticipations(data.anticipations)
						} else {
							addAsaasAnticipations(data.anticipations)
						}
						setHasMore(data.hasMore)
					} else if (responseStatusCode !== 200) {
						setOpenAlert(true)
						setAlertErrorMessage(error?.message || t('error-server-default'))
					}
				},
				onError: error => {
					setOpenAlert(true)
					setAlertErrorMessage(t('error-server-default'))
					console.error(error)
				}
			}
		)
	}, [
		mutateFetch,
		t,
		offset,
		limit,
		setAsaasAnticipations,
		addAsaasAnticipations
	])

	const handleOpenDetails = (id: string) => {
		setAnticipationId(id)
		setOpenDetails(true)
	}

	const handleCloseDetails = () => {
		setAnticipationId(null)
		setOpenDetails(false)
	}

	const {isFetching} = useQuery(
		['asaas-anticipation-config'],
		getAnticipationConfigAsaas,
		{
			refetchOnWindowFocus: false,
			onSuccess: ({data, error, responseStatusCode}) => {
				if (responseStatusCode === 200 && data) {
					setLimits({
						total: data.total,
						available: data.available
					})
				} else {
					setErrorMessage(error?.message || t('error-server-default'))
					setOpenAlertError(true)
				}
			},
			onError: error => {
				console.error(error)
				setErrorMessage(t('error-server-default'))
				setOpenAlertError(true)
			}
		}
	)

	useEffect(() => {
		if (firstLoad) {
			fetchAnticipations()
			setFirstLoad(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [firstLoad])

	return (
		<>
			<AlertDialog
				severity="error"
				open={openAlertError}
				message={errorMessage}
				onClose={() => setOpenAlertError(false)}
			/>
			{anticipationId && (
				<AnticipationDetails
					anticipationId={anticipationId}
					open={openDetails}
					onClose={handleCloseDetails}
				/>
			)}
			<Stack spacing={4}>
				<Box>
					<Typography variant="h5" component="h2">
						{t('anticipation-history')}
					</Typography>
					<Typography
						variant="body2"
						sx={[
							styles.detailsText,
							{display: 'flex', alignItems: 'center', gap: 0.5, mt: 0.5}
						]}
					>
						{t('available-for-anticipation')}:{' '}
						{isFetching ? (
							<Skeleton
								variant="rounded"
								width={100}
								height={12}
								sx={{display: 'inline-block'}}
							/>
						) : (
							<b>{price.full(limits.available)}</b>
						)}
						<InfoPopover>
							{parseToHTML(
								t('available-for-anticipation-describe', {
									anticipationLimit: price.full(limits.total),
									availableToAnticipate: price.full(limits.available)
								})
							)}
						</InfoPopover>
					</Typography>
					{openAlert && (
						<AlertMessage
							severity="error"
							open={openAlert}
							message={alertErrorMessage}
							onClose={() => setOpenAlert(false)}
						/>
					)}
					<Box display="grid" sx={{mt: 2}}>
						<TableContainer component={Paper} variant="outlined">
							<Table sx={{minWidth: '600px'}}>
								<TableHead>
									<TableRow>
										<TableCell sx={{pl: 3}}>{t('date')}</TableCell>
										<TableCell>{t('anticipation-fee')}</TableCell>
										<TableCell>{t('net-amount')}</TableCell>
										<TableCell sx={{pr: 3}}>{t('status')}</TableCell>
										<TableCell></TableCell>
									</TableRow>
								</TableHead>
								<TableBody
									sx={{
										'& tr:last-child td': {
											borderBottomWidth: 0
										}
									}}
								>
									{asaasAnticipations.length === 0 && (
										<TableRow>
											<TableCell colSpan={4} sx={{borderBottomWidth: 0}}>
												<Typography variant="body2" align="center">
													{t('no-transfers')}
												</Typography>
											</TableCell>
										</TableRow>
									)}
									{asaasAnticipations.map(anticipation => (
										<TableRow key={anticipation.id}>
											<TableCell sx={{pl: 3}}>
												<Typography variant="body2">
													{date.convertISOToLocation(
														anticipation.createdAt,
														currentLang
													)}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2">
													{anticipation.feeAmount
														? price.full(anticipation.feeAmount)
														: '-'}
												</Typography>
											</TableCell>
											<TableCell>
												<Typography variant="body2">
													{anticipation.feeAmount
														? price.full(anticipation.netAmount)
														: '-'}
												</Typography>
											</TableCell>
											<TableCell>
												<Status.Tag type={anticipation.status}>
													<Status.Label
														label={t('status-tag-' + anticipation.status)}
														maxWidth="auto"
														showTooltip={false}
													/>
												</Status.Tag>
											</TableCell>
											<TableCell align="right">
												<Button
													variant="outlined"
													size="small"
													onClick={() => handleOpenDetails(anticipation.id)}
												>
													{t('view-details')}
												</Button>
											</TableCell>
										</TableRow>
									))}
									{hasMore && (
										<TableRow>
											<TableCell
												colSpan={4}
												sx={{
													padding: '0px !important'
												}}
											>
												<Button
													onClick={handleLoadMore}
													sx={{width: '100%'}}
													disabled={isLoadingAnticipations}
												>
													{isLoadingAnticipations ? (
														<CircularProgress color="inherit" size={24} />
													) : (
														t('load-more')
													)}
												</Button>
											</TableCell>
										</TableRow>
									)}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Box>
			</Stack>
		</>
	)
}

const styles = {
	detailsItemBox: {
		display: 'flex',
		flexWrap: 'wrap',
		flexDirection: 'column',
		columnGap: 7.5,
		rowGap: 2,
		mt: 3
	},
	detailsSubtitle: {
		mb: 0.5
	},
	detailsText: {
		color: 'grey.600'
	}
}
