import {SaleMetricsPreferencesFilter} from '../../stores/useSaleStates'
import useAppStates from '../../stores/useAppStates'

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const makeMetricsFilter = (filter: SaleMetricsPreferencesFilter) => {
	const currentLang = useAppStates.getState().currentLang

	switch (filter) {
		case 'last-7-days': {
			const last7Days = dayjs()
				.tz(dayjs.tz.guess())
				.utc()
				.subtract(7, 'day')
				.startOf('day')
			const toDate = dayjs().tz(dayjs.tz.guess()).utc().endOf('day')
			return {
				fromDate: last7Days.toISOString(),
				toDate: toDate.toISOString(),
				xAxisLabels: new Array(7).fill(0).map((_, i) => String(i))
			}
		}
		case 'last-30-days': {
			const last30Days = dayjs()
				.tz(dayjs.tz.guess())
				.utc()
				.subtract(30, 'day')
				.startOf('day')
			const toDate = dayjs().tz(dayjs.tz.guess()).utc().endOf('day')
			return {
				fromDate: last30Days.toISOString(),
				toDate: toDate.toISOString(),
				xAxisLabels: new Array(30).fill(0).map((_, i) => String(i))
			}
		}
		case 'last-12-months': {
			const fromDate = dayjs()
				.tz(dayjs.tz.guess())
				.utc()
				.subtract(12, 'month')
				.startOf('month')
			const toDate = dayjs().tz(dayjs.tz.guess()).utc().endOf('month')
			return {
				fromDate: fromDate.toISOString(),
				toDate: toDate.toISOString(),
				xAxisLabels: new Array(12)
					.fill(0)
					.map((_, i) => String((new Date().getMonth() + 1 + i) % 12))
			}
		}
		case 'last-year': {
			const fromDate = dayjs()
				.tz(dayjs.tz.guess())
				.utc()
				.subtract(1, 'year')
				.startOf('year')
			const toDate = dayjs()
				.tz(dayjs.tz.guess())
				.utc()
				.subtract(1, 'year')
				.endOf('year')
			return {
				fromDate: fromDate.toISOString(),
				toDate: toDate.toISOString(),
				xAxisLabels: new Array(12).fill(0).map((_, i) => String(i))
			}
		}
		case 'current-year': {
			const fromDate = dayjs().tz(dayjs.tz.guess()).utc().startOf('year')
			const toDate = dayjs().tz(dayjs.tz.guess()).utc().endOf('year')
			return {
				fromDate: fromDate.toISOString(),
				toDate: toDate.toISOString(),
				xAxisLabels: new Array(12)
					.fill(0)
					.map((_, i) => String(i))
					.slice(0, new Date().getMonth() + 1)
			}
		}
		case 'today': {
			const timezoneOffset = dayjs().tz(dayjs.tz.guess()).format('Z')
			const seconds = convertOffsetToSeconds(timezoneOffset)
			const fromDate = dayjs()
				.tz(dayjs.tz.guess())
				.startOf('day')
				.add(seconds, 'seconds')
			const toDate = dayjs()
				.tz(dayjs.tz.guess())
				.endOf('day')
				.add(seconds, 'seconds')
			return {
				fromDate: fromDate.toISOString(),
				toDate: toDate.toISOString(),
				xAxisLabels: new Array(24).fill(0).map((_, i) => {
					return new Date(0, 0, 0, i, 0, 0).toLocaleTimeString(currentLang, {
						hour: '2-digit',
						minute: '2-digit'
					})
				})
			}
		}
		case 'yesterday': {
			const timezoneOffset = dayjs().tz(dayjs.tz.guess()).format('Z')
			const seconds = convertOffsetToSeconds(timezoneOffset)
			const fromDate = dayjs()
				.tz(dayjs.tz.guess())
				.subtract(1, 'day')
				.startOf('day')
				.add(seconds, 'seconds')
			const toDate = dayjs()
				.tz(dayjs.tz.guess())
				.subtract(1, 'day')
				.endOf('day')
				.add(seconds, 'seconds')
			return {
				fromDate: fromDate.toISOString(),
				toDate: toDate.toISOString(),
				xAxisLabels: new Array(24).fill(0).map((_, i) => {
					return new Date(0, 0, 0, i, 0, 0).toLocaleTimeString(currentLang, {
						hour: '2-digit',
						minute: '2-digit'
					})
				})
			}
		}
		default: {
			const timezoneOffset = dayjs().tz(dayjs.tz.guess()).format('Z')
			const seconds = convertOffsetToSeconds(timezoneOffset)
			const last7Days = dayjs()
				.tz(dayjs.tz.guess())
				.subtract(7, 'day')
				.startOf('day')
				.add(seconds, 'seconds')
			const toDate = dayjs()
				.tz(dayjs.tz.guess())
				.endOf('day')
				.add(seconds, 'seconds')
			return {
				fromDate: last7Days.toISOString(),
				toDate: toDate.toISOString(),
				xAxisLabels: new Array(7).fill(0).map((_, i) => String(i))
			}
		}
	}
}

function convertOffsetToSeconds(offset: string) {
	// Verifica o sinal (+ ou -) do offset
	const sign = offset[0] === '-' ? -1 : 1

	// Separa horas e minutos do offset (removendo o sinal)
	const [hours, minutes] = offset.substring(1).split(':').map(Number)

	// Converte horas e minutos para segundos e aplica o sinal
	const totalSeconds = sign * (hours * 60 * 60 + minutes * 60)

	return totalSeconds
}
