import {useState} from 'react'
import {styled, Theme} from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import Typography from '@mui/material/Typography'
import {FiMenu} from 'react-icons/fi'
import {CgClose} from 'react-icons/cg'
import {useTranslation} from 'react-i18next'
import Link from '../navigation/Link'
import MainMenu from './MainMenu'
import ProfileMenu from './ProfileMenu'
import LanguageMenu from './LanguageMenu'
import Mobile from '../layout/responsive/Mobile'
import {CompanyLogo} from '../logos/CompanyLogo'

const Header = () => {
	const [openMenu, setOpenMenu] = useState(false)
	const {t} = useTranslation()

	return (
		<AppBar>
			<Toolbar variant="dense" sx={style.containerHeader}>
				<Mobile>
					<MenuIcon
						onClick={() => setOpenMenu(true)}
						sx={theme => style.iconMenu(theme)}
					/>
				</Mobile>
				<Link to="/" sx={style.linkLogo}>
					<CompanyLogo />
				</Link>
				<Nav>
					<Mobile>
						<SwipeableDrawer
							anchor="left"
							open={openMenu}
							onClose={() => setOpenMenu(false)}
							onOpen={() => setOpenMenu(true)}
							PaperProps={{sx: style.drawerPaper}}
						>
							<Toolbar sx={style.closeMenu} onClick={() => setOpenMenu(false)}>
								<Typography>{t('menu')}</Typography>
								<CloseIcon sx={theme => style.iconClose(theme)} />
							</Toolbar>
							<Divider />
							<MainMenu onClose={() => setOpenMenu(false)} />
						</SwipeableDrawer>
					</Mobile>
					<LanguageMenu />
					<ProfileMenu />
				</Nav>
			</Toolbar>
		</AppBar>
	)
}

interface NavProps {
	children: React.ReactNode
}

const Nav = (props: NavProps) => {
	return (
		<Box ml="auto" component="nav" sx={{display: 'flex'}}>
			{props.children}
		</Box>
	)
}

const MenuIcon = styled(FiMenu)``
const CloseIcon = styled(CgClose)``

const style = {
	closeMenu: {
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	containerHeader: {
		alignItems: 'center',
		display: 'flex',
		minHeight: '68px'
	},
	drawerPaper: {
		width: '80vw',
		maxWidth: '240px'
	},
	iconClose: (theme: Theme) => {
		return {
			color: theme.palette.common.black,
			height: 23,
			width: 23
		}
	},
	iconMenu: (theme: Theme) => {
		return {
			color: theme.palette.grey[900],
			height: 23,
			width: 23,
			mr: 2,
			minWidth: 23
		}
	},
	linkLogo: {
		textDecoration: 'none',
		width: 'max-content',
		'@media (max-width: 405px)': {
			display: 'none'
		}
	}
}

export default Header
