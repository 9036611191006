import {useCallback, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useMutation, useQueryClient} from '@tanstack/react-query'
import {useFormik} from 'formik'
import dayjs from 'dayjs'
import 'dayjs/locale/pt-br'
import 'dayjs/locale/en'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {DatePicker} from '@mui/x-date-pickers/DatePicker'
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Autocomplete,
	Box,
	Button,
	Card,
	Checkbox,
	CircularProgress,
	FormControl,
	FormControlLabel,
	FormHelperText,
	IconButton,
	InputAdornment,
	Tooltip,
	Radio,
	RadioGroup,
	Skeleton,
	Stack,
	TextField,
	Typography
} from '@mui/material'
import {Theme} from '@mui/material/styles'
import {MdOutlineCheck as CheckIcon} from 'react-icons/md'
import {FaCalculator as CalculatorIcon} from 'react-icons/fa'
import {RiArrowDownSFill as ArrowDropDownIcon} from 'react-icons/ri'
import {CgClose as ClearIcon} from 'react-icons/cg'

import {INPUT} from '../../../config'
import useAuthStates from '../../../stores/useAuthStates'
import useAppStates from '../../../stores/useAppStates'
import useEditionSchema from '../../../schemas/useEditionSchema'
import {Edition} from '../../../entities/Edition'
import {checkEditionSlug, updateEdition} from '../../../api/editions'
import {regex} from '../../../helpers/regex'
import SectionTitle from '../../typography/SectionTitle'
import AlertMessage from '../../AlertMessage'
import Tag from '../../typography/Tag'
import InfoPopover from '../../InfoPopover'
import {Dialog} from '../../Dialog'
import FormFeesCalculator from '../../FormFeesCalculator'
import CreditCardFees from '../../PagarmeFeesCalculator/CreditCardFees'
import BoletoFees from '../../PagarmeFeesCalculator/BoletoFees'
import PixFees from '../../PagarmeFeesCalculator/PixFees'
import {
	BoletoFeesCalculatorData,
	CreditCardFeesCalculatorData,
	PixFeesCalculatorData
} from '../../../api/feesCalculator'
import {OrderBumpCard} from './integrations/OrderBump'
import {SaleSimulate} from '../../SaleSimulate'
import ButtonIcon from '../../ButtonIcon'
import InputQuantity from '../../InputQuantity'
import {CustomizeRateApplication} from './CustomizeRateApplication'
import SwitchControl from '../../SwitchControl'

const convertLocaleToDayjs = (locale: string) => {
	if (locale === 'pt-BR' || locale === 'pt-br' || locale === 'pt') {
		return 'pt-br'
	}

	return 'en'
}

interface FormUpdateEditionProps {
	edition?: Edition
	isFetching?: boolean
}

const FormUpdateEdition = ({edition, isFetching}: FormUpdateEditionProps) => {
	const {t} = useTranslation()
	const {paymentService} = useAuthStates()

	const currentLanguage = useAppStates(state => state.currentLang)
	const [showSuccessButton, setShowSuccessButton] = useState<boolean>(false)
	const [openAlert, setOpenAlert] = useState<boolean>(false)
	const [alertMessage, setAlertMessage] = useState<string>('')
	const [lastSlugChecked, setLastSlugChecked] = useState<string>('')
	const [slugAvailable, setSlugAvailable] = useState<boolean>(false)
	const [slugHelper, setSlugHelper] = useState<string>(t('helper-slug'))
	const [openFeesCalculator, setOpenFeesCalculator] = useState<boolean>(false)
	const [openFeesDetails, setOpenFeesDetails] = useState<boolean>(false)
	const [openSimulateSale, setOpenSimulateSale] = useState(false)
	const [paymentMethod, setPaymentMethod] = useState<
		'credit-card' | 'boleto' | 'pix'
	>('credit-card')
	const [creditCardFees, setCreditCardFees] =
		useState<CreditCardFeesCalculatorData>()
	const [boletoFees, setBoletoFees] = useState<BoletoFeesCalculatorData>()
	const [pixFees, setPixFees] = useState<PixFeesCalculatorData>()
	const [checkoutType, setCheckoutType] = useState<
		'one-page' | 'multiple-page'
	>('one-page')
	const [enabledPaymentMethodsError, setEnabledPaymentMethodsError] =
		useState<string>()
	const enabledPaymentMethodsErrorRef = useRef<HTMLDivElement>(null)
	const [expandedPanel1, setExpandedPanel1] = useState(false)

	const [openDatePickerLimitDate, setOpenDatePickerLimitDate] = useState(false)
	const [openDatePickerStartDate, setOpenDatePickerStartDate] = useState(false)

	const sellerId = useAuthStates(state => state.sellerId)
	const editionSchema = useEditionSchema()
	const queryClient = useQueryClient()
	const {isLoading, mutate} = useMutation(updateEdition, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			title: edition?.title || '',
			slug: edition?.slug || '',
			description: edition?.description || '',
			price:
				regex.formatBRLCurrency(
					Number.parseFloat(String(edition?.price)).toFixed(2) // add 2 decimal places to price from database
				) || '',
			pricingUnit: edition?.pricingUnit || null,
			vacancies: edition?.vacancies || undefined,
			startDate: edition?.startDate ? dayjs(new Date(edition.startDate)) : null,
			limitDateToSell: edition?.limitDateToSell
				? dayjs(new Date(edition.limitDateToSell))
				: null,
			anticipationEnabled: Boolean(edition?.anticipationEnabled),
			passAnticipationFeesToCustomer: Boolean(
				edition?.passAnticipationFeesToCustomer
			),
			passCreditCardFeesToCustomer: Boolean(
				edition?.passCreditCardFeesToCustomer
			),
			allowMultipleItems: Boolean(edition?.allowMultipleItems),
			enabledCreditCard: Boolean(edition?.enabledCreditCard),
			enabledPix: Boolean(edition?.enabledPix),
			enabledBankSlip: Boolean(edition?.enabledBankSlip),
			maxInstallments: edition?.maxInstallments || 12,
			defaultInstallments: edition?.defaultInstallments || 12,
			passExcofyFeesToCustomer: Boolean(edition?.passExcofyFeesToCustomer),
			additionalProfitType:
				edition?.additionalProfitType || 'monthly-interest-rate',
			additionalProfitAmount: edition?.additionalProfitAmount || 0,
			additionalProfitEnabled: Boolean(edition?.additionalProfitEnabled)
		},
		enableReinitialize: true,
		validationSchema: editionSchema,
		onSubmit: values => {
			setOpenAlert(false)
			mutate(
				{
					...values,
					editionId: edition?._id as string,
					experienceId: edition?.experience._id as string,
					vacancies: formik.values.vacancies
						? Number(formik.values.vacancies)
						: undefined,
					price: regex.parseBRLCurrencyToFloat(
						String(formik.values.price || 0)
					),
					limitDateToSell:
						formik.values.limitDateToSell?.toISOString() || undefined,
					startDate: formik.values.startDate?.toISOString() || undefined,
					pricingUnit: formik.values.pricingUnit || null,
					checkoutType
				},
				{
					onSuccess: ({responseStatusCode, error}) => {
						if (responseStatusCode === 200) {
							setShowSuccessButton(true)

							queryClient.invalidateQueries([
								`experiences-${sellerId}`,
								`experience-${edition?.experience.slug}`,
								`editions-${values.slug}`,
								`edition-${values.slug}`
							])

							window.history.replaceState(
								null,
								'Dashboard | Excofy',
								`/products/${edition?.experience.slug}/${values.slug}/edit`
							)
						}

						if (responseStatusCode === 400 && error?.fields) {
							Object.entries(error.fields).forEach(([key, value]) => {
								formik.setFieldError(key, value[0].message)
							})
						}

						if (responseStatusCode === 500) {
							setAlertMessage(t('error-server-default'))
							setOpenAlert(true)
						}
					},
					onError: error => {
						setAlertMessage(t('error-server-default'))
						setOpenAlert(true)
						console.error(error)
					}
				}
			)
		}
	})

	const handleOnChangeForm = () => {
		setShowSuccessButton(false)
		setOpenAlert(false)
	}

	const {isLoading: isCheckingSlug, mutate: mutateCheckSlug} = useMutation(
		checkEditionSlug,
		{
			networkMode: 'always'
		}
	)

	const checkAvailableSlug = (slug: string) => {
		if (
			!formik.errors.slug &&
			formik.values.slug !== '' &&
			lastSlugChecked !== formik.values.slug &&
			formik.values.slug !== edition?.slug
		) {
			setSlugAvailable(false)
			mutateCheckSlug(
				{
					slug: formik.values.slug,
					experienceId: edition?.experience._id as string
				},
				{
					onSuccess: result => {
						if (result?.responseStatusCode === 200 && result?.data) {
							setSlugAvailable(true)
							if (formik.values.slug !== result.data.slug) {
								setSlugHelper(t('helper-slug-already-in-use'))
							}
							formik.setFieldValue('slug', result.data.slug)
							setLastSlugChecked(result.data.slug)
						}
						if (
							result?.responseStatusCode === 400 &&
							result?.error?.fields?.slug
						) {
							formik.setFieldError('slug', result.error.fields.slug[0].message)
						}
					}
				}
			)
		}
	}

	const validateEnabledPaymentMethods = useCallback(
		(
			enabledCreditCard: boolean,
			enabledPix: boolean,
			enabledBankSlip: boolean
		) => {
			if (!enabledCreditCard && !enabledPix && !enabledBankSlip) {
				setEnabledPaymentMethodsError(t('error-at-least-one-payment-method'))
			} else {
				setEnabledPaymentMethodsError(undefined)
			}
		},
		[t]
	)

	const submitForm = useCallback(
		(e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault()
			if (enabledPaymentMethodsError) {
				setExpandedPanel1(true)
				enabledPaymentMethodsErrorRef?.current?.scrollIntoView({
					behavior: 'smooth',
					block: 'center'
				})
			} else {
				formik.handleSubmit(e)
			}
		},
		[formik, enabledPaymentMethodsError]
	)

	useEffect(() => {
		if (edition) {
			setCheckoutType(edition.checkoutType)
		}
	}, [edition])

	return (
		<Box
			component="form"
			autoComplete="off"
			onSubmit={submitForm}
			onChange={handleOnChangeForm}
			sx={style.form.box}
		>
			<Dialog.Root open={openFeesCalculator} maxWidth={900}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenFeesCalculator(false)}>
						{t('fees-calculator')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width="100%">
					<FormFeesCalculator
						amount={regex.parseBRLCurrencyToFloat(
							String(formik.values.price || 0)
						)}
						installments={2}
						paymentMethod={paymentMethod}
						setPaymentMethod={setPaymentMethod}
						onChange={() => {}}
						onSuccess={data => {
							if (paymentMethod === 'credit-card') {
								setCreditCardFees(data as CreditCardFeesCalculatorData)
							} else if (paymentMethod === 'pix') {
								setPixFees(data as PixFeesCalculatorData)
							} else {
								setBoletoFees(data as BoletoFeesCalculatorData)
							}
							setOpenFeesDetails(true)
						}}
					/>
				</Dialog.Body>
			</Dialog.Root>
			<Dialog.Root open={openFeesDetails} maxWidth={900}>
				<Dialog.Header>
					<Dialog.Title onClose={() => setOpenFeesDetails(false)}>
						{t('fees-details')}
					</Dialog.Title>
				</Dialog.Header>
				<Dialog.Body width="100%">
					<Card variant="outlined">
						<Box>
							{creditCardFees && paymentMethod === 'credit-card' ? (
								<CreditCardFees fees={creditCardFees} />
							) : null}

							{boletoFees && paymentMethod === 'boleto' ? (
								<BoletoFees fees={boletoFees} />
							) : null}

							{pixFees && paymentMethod === 'pix' ? (
								<PixFees fees={pixFees} />
							) : null}
						</Box>
					</Card>
				</Dialog.Body>
			</Dialog.Root>
			<SaleSimulate
				open={openSimulateSale}
				onClose={() => setOpenSimulateSale(false)}
				initialAmount={regex.parseBRLCurrencyToFloat(
					String(formik.values.price || 0)
				)}
				initialAnticipationEnabled={formik.values.anticipationEnabled}
				initialPassAnticipationFeesToCustomer={
					formik.values.passAnticipationFeesToCustomer
				}
				initialPassCreditCardFeesToCustomer={
					formik.values.passCreditCardFeesToCustomer
				}
				initialPassExcofyFee={formik.values.passExcofyFeesToCustomer}
				maxInstallments={formik.values.maxInstallments}
				defaultInstallments={formik.values.defaultInstallments}
				additionalProfit={{
					enabled: formik.values.additionalProfitEnabled,
					monthlyInterestRate:
						formik.values.additionalProfitType === 'monthly-interest-rate'
							? formik.values.additionalProfitAmount
							: 0,
					percentageOfGrossAmount:
						formik.values.additionalProfitType === 'percentage-fee'
							? formik.values.additionalProfitAmount
							: 0
				}}
			/>
			<SectionTitle sx={{mb: 3}}>{t('edit-edition')}</SectionTitle>
			<Stack spacing={6}>
				<Box>
					{isFetching ? (
						<Stack direction="column" spacing={4} pr={3}>
							<Skeleton variant="rounded" height={40} />
							<Skeleton variant="rounded" height={40} />
							<FormHelperText sx={{my: '8px !important', px: 1}}>
								<Skeleton variant="rounded" height={12} width="80%" />
							</FormHelperText>
							<Skeleton variant="rounded" height={109} />
							<Box sx={{display: 'flex', alignItems: 'flex-start', gap: 6}}>
								<Box width="100%">
									<Skeleton variant="rounded" height={40} width="100%" />
								</Box>
								<Box width="100%">
									<Skeleton variant="rounded" height={40} width="100%" />
									<FormHelperText sx={{my: '8px !important', px: 1}}>
										<Skeleton variant="rounded" height={12} width={80} />
									</FormHelperText>
								</Box>
							</Box>
							<Skeleton variant="rounded" height={40} />
							<FormHelperText sx={{my: '8px !important', px: 1}}>
								<Skeleton variant="rounded" height={12} width={80} />
							</FormHelperText>
							<Box sx={{display: 'flex', alignItems: 'flex-start', gap: 6}}>
								<Box width="100%">
									<Skeleton variant="rounded" height={40} width="100%" />
									<FormHelperText sx={{my: '8px !important', px: 1}}>
										<Skeleton variant="rounded" height={12} width={80} />
									</FormHelperText>
								</Box>
								<Box width="100%">
									<Skeleton variant="rounded" height={40} width="100%" />
									<FormHelperText sx={{my: '8px !important', px: 1}}>
										<Skeleton variant="rounded" height={12} width={80} />
									</FormHelperText>
								</Box>
							</Box>
							<Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 1
									}}
								>
									<Typography variant="h6">
										<Skeleton variant="text" width={240} />
									</Typography>
								</Box>
								<Typography mb={1} mt={0.5}>
									<Skeleton variant="text" />
								</Typography>
								<Typography mb={1} mt={0.5}>
									<Skeleton variant="rounded" width={120} height={20} />
								</Typography>
							</Box>
							<Box>
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: 1
									}}
								>
									<Typography variant="h6">
										<Skeleton variant="text" width={240} />
									</Typography>
								</Box>
								<Skeleton variant="rounded" height={100} sx={{mt: 2}} />
								<Skeleton
									variant="rounded"
									height={40}
									width={200}
									sx={{mt: 4}}
								/>
							</Box>
						</Stack>
					) : (
						<>
							<Stack direction="column" spacing={4} pr={3}>
								{openAlert && (
									<AlertMessage
										open={openAlert}
										severity="error"
										message={alertMessage}
										onClose={() => setOpenAlert(false)}
										mt={0}
										sx={{
											mr: 3
										}}
									/>
								)}
								<TextField
									name="title"
									type="text"
									label={t('title')}
									value={formik.values.title}
									onChange={event => {
										const currentSlug = regex.formatStringToSlug(
											event.target.value
										)
										formik.setFieldValue('slug', currentSlug)
										formik.handleChange(event)
									}}
									onBlur={event => {
										formik.handleBlur(event)
										checkAvailableSlug(event.target.value)
									}}
									helperText={formik.touched.title && formik.errors.title}
									error={formik.touched.title && Boolean(formik.errors.title)}
									variant="outlined"
									size="small"
									inputProps={{maxLength: INPUT.EDITION.title.max}}
									InputProps={{
										endAdornment: (
											<InfoPopover sx={{position: 'absolute', right: -34}}>
												<Typography variant="body2">
													{t('info-title-offer')}
												</Typography>
											</InfoPopover>
										)
									}}
									sx={{mt: '0px !important'}}
								/>
								<TextField
									name="slug"
									type="text"
									label={t('slug')}
									value={formik.values.slug}
									onChange={event => {
										formik.handleChange(event)
										setSlugHelper(t('helper-slug'))
									}}
									onBlur={event => {
										formik.handleBlur(event)
										checkAvailableSlug(event.target.value)
									}}
									helperText={
										formik.touched.slug && Boolean(formik.errors.slug)
											? formik.errors.slug
											: slugHelper
									}
									error={formik.touched.slug && Boolean(formik.errors.slug)}
									variant="outlined"
									size="small"
									inputProps={{maxLength: INPUT.EDITION.slug.max}}
									InputProps={{
										endAdornment: (
											<>
												<InputAdornment position="end">
													{isCheckingSlug && (
														<CircularProgress color="primary" size={20} />
													)}
													{!isCheckingSlug &&
														slugAvailable &&
														(lastSlugChecked === formik.values.slug ||
															formik.values.slug === edition?.slug) && (
															<IconButton sx={style.form.inputCheckIcon}>
																<CheckIcon size={20} />
															</IconButton>
														)}
												</InputAdornment>
												<InfoPopover sx={{position: 'absolute', right: -34}}>
													<Typography variant="body2">
														{t('info-edition-slug')}
													</Typography>
												</InfoPopover>
											</>
										)
									}}
								/>
								<TextField
									name="description"
									type="text"
									label={t('description')}
									multiline
									rows={4}
									value={formik.values.description}
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									helperText={
										formik.touched.description && formik.errors.description
									}
									error={
										formik.touched.description &&
										Boolean(formik.errors.description)
									}
									inputProps={{maxLength: INPUT.EDITION.description.max}}
									variant="outlined"
									size="small"
									InputProps={{
										endAdornment: (
											<InfoPopover sx={{position: 'absolute', right: -34}}>
												<Typography variant="body2">
													{t('info-edition-description')}
												</Typography>
											</InfoPopover>
										)
									}}
								/>
								<Box
									sx={theme => ({
										display: 'flex',
										gap: 6,
										[theme.breakpoints.down('sm')]: {
											flexDirection: 'column',
											gap: 4
										}
									})}
								>
									<TextField
										name="price"
										label={t('price')}
										value={formik.values.price}
										onChange={event => {
											event.target.value = regex.formatBRLCurrency(
												event.target.value
											)
											formik.handleChange(event)
										}}
										onBlur={formik.handleBlur}
										helperText={formik.touched.price && formik.errors.price}
										error={formik.touched.price && Boolean(formik.errors.price)}
										variant="outlined"
										size="small"
										InputProps={{
											startAdornment: (
												<>
													<InputAdornment position="start">
														{'R$'}
													</InputAdornment>
													<InfoPopover sx={{position: 'absolute', right: -34}}>
														<Typography variant="body2">
															{t('info-edition-price')}
														</Typography>
													</InfoPopover>
												</>
											),
											endAdornment: (
												<ButtonIcon
													title={t('to-simulate-sale')}
													size="medium"
													padding={0.75}
													icon={<CalculatorIcon />}
													onClick={() => setOpenSimulateSale(true)}
												/>
											)
										}}
										fullWidth
									/>
									<Autocomplete
										freeSolo
										id="free-solo-demo"
										options={[
											t('unit-item'),
											t('unit-person'),
											t('unit-service'),
											t('unit-spot')
										].map(option => option)}
										value={formik.values.pricingUnit}
										onChange={(event, value) => {
											formik.setFieldValue('pricingUnit', value)
											handleOnChangeForm()
										}}
										fullWidth
										renderInput={params => (
											<TextField
												{...params}
												name="pricingUnit"
												label={t('unit')}
												value={formik.values.pricingUnit}
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												helperText={
													formik.touched.pricingUnit &&
													Boolean(formik.errors.pricingUnit) ? (
														formik.errors.pricingUnit
													) : (
														<Tag>{t('optional')}</Tag>
													)
												}
												error={
													formik.touched.pricingUnit &&
													Boolean(formik.errors.pricingUnit)
												}
												variant="outlined"
												size="small"
											/>
										)}
									/>
								</Box>
								<TextField
									name="vacancies"
									type="text"
									label={t('vacancies')}
									value={formik.values.vacancies?.toString() || ''}
									onChange={event => {
										event.target.value = regex.formatStringToNumber(
											event.target.value
										)
										formik.handleChange(event)
									}}
									onBlur={formik.handleBlur}
									helperText={
										formik.touched.vacancies &&
										Boolean(formik.errors.vacancies) ? (
											formik.errors.vacancies
										) : (
											<Tag>{t('optional')}</Tag>
										)
									}
									error={
										formik.touched.vacancies && Boolean(formik.errors.vacancies)
									}
									variant="outlined"
									size="small"
									InputProps={{
										endAdornment: (
											<InfoPopover sx={{position: 'absolute', right: -34}}>
												<Typography variant="body2">
													{t('info-edition-vacancies')}
												</Typography>
											</InfoPopover>
										)
									}}
								/>
								<LocalizationProvider
									dateAdapter={AdapterDayjs}
									adapterLocale={convertLocaleToDayjs(currentLanguage)}
								>
									<FormControl
										sx={[style.form.control, style.form.controlDate]}
									>
										<Box
											sx={theme => ({
												display: 'flex',
												position: 'relative',
												width: '50%',
												[theme.breakpoints.down('sm')]: {
													width: '100%'
												}
											})}
										>
											<DatePicker
												label={t('limit-date-to-sell')}
												value={formik.values.limitDateToSell}
												minDate={dayjs(Date.now())}
												dayOfWeekFormatter={day => `${day}`}
												onChange={newValue => {
													formik.setFieldValue('limitDateToSell', newValue)
												}}
												open={openDatePickerLimitDate}
												onClose={() => {
													setOpenDatePickerLimitDate(false)
												}}
												slotProps={{
													textField: {
														name: 'limitDateToSell',
														type: 'text',
														label: t('limit-date-to-sell'),
														value: formik.values.limitDateToSell,
														error: Boolean(formik.errors.limitDateToSell),
														helperText:
															formik.touched.limitDateToSell &&
															Boolean(formik.errors.limitDateToSell) ? (
																formik.errors.limitDateToSell
															) : (
																<Tag>{t('optional')}</Tag>
															),
														variant: 'outlined',
														size: 'small',
														sx: {
															flexGrow: 1
														},
														onClick: () => {
															setOpenDatePickerLimitDate(true)
														},
														InputProps: {
															endAdornment: null
														}
													}
												}}
												slots={<TextField />}
											/>
											{formik.values?.limitDateToSell && (
												<Tooltip title={t('to-remove')} arrow>
													<IconButton
														size="small"
														title={t('to-remove')}
														onClick={() =>
															formik.setFieldValue('limitDateToSell', null)
														}
														sx={{
															position: 'absolute',
															right: '8px',
															top: '6px'
														}}
													>
														<ClearIcon />
													</IconButton>
												</Tooltip>
											)}
											<Box sx={style.boxInfoPopover}>
												<InfoPopover sx={{position: 'absolute', right: -34}}>
													<Typography variant="body2">
														{t('info-edition-limit-date-to-sell')}
													</Typography>
												</InfoPopover>
											</Box>
										</Box>
										<Box
											sx={theme => ({
												display: 'flex',
												position: 'relative',
												width: '50%',
												[theme.breakpoints.down('sm')]: {
													width: '100%'
												}
											})}
										>
											<DatePicker
												value={formik.values.startDate}
												minDate={dayjs(Date.now())}
												dayOfWeekFormatter={day => `${day}`}
												onChange={newValue => {
													formik.setFieldValue('startDate', newValue)
												}}
												open={openDatePickerStartDate}
												onClose={() => {
													setOpenDatePickerStartDate(false)
												}}
												slots={<TextField />}
												slotProps={{
													textField: {
														name: 'startDate',
														type: 'text',
														label: t('start-date'),
														value: formik.values.startDate,
														helperText:
															formik.touched.startDate &&
															Boolean(formik.errors.startDate) ? (
																formik.errors.startDate
															) : (
																<Tag>{t('optional')}</Tag>
															),
														error: Boolean(formik.errors.startDate),
														variant: 'outlined',
														size: 'small',
														sx: {
															flexGrow: 1
														},
														onClick: () => {
															setOpenDatePickerStartDate(true)
														},
														InputProps: {
															endAdornment: null
														}
													}
												}}
											/>
											{formik.values?.startDate && (
												<Tooltip title={t('to-remove')} arrow>
													<IconButton
														size="small"
														title={t('to-remove')}
														onClick={() =>
															formik.setFieldValue('startDate', null)
														}
														sx={{
															position: 'absolute',
															right: '8px',
															top: '6px'
														}}
													>
														<ClearIcon />
													</IconButton>
												</Tooltip>
											)}
											<Box sx={style.boxInfoPopover}>
												<InfoPopover sx={{position: 'absolute', right: -34}}>
													<Typography variant="body2">
														{t('info-edition-start-date')}
													</Typography>
												</InfoPopover>
											</Box>
										</Box>
									</FormControl>
								</LocalizationProvider>
								{paymentService?.activeService === 'asaas' && (
									<Box>
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: 1
											}}
										>
											<Typography variant="h6">
												{t('payments-anticipation')}
											</Typography>
											<InfoPopover
												iconSx={{
													p: 0,
													mt: '-3px',
													width: '16px'
												}}
											>
												{t('automatic-anticipation-describe-2')}
											</InfoPopover>
										</Box>
										<Typography mb={1} mt={0.5}>
											{t('payments-anticipation-description')}
										</Typography>
										<SwitchControl
											checked={formik.values.anticipationEnabled}
											onChange={event => {
												formik.setFieldValue(
													'anticipationEnabled',
													event.target.checked
												)
											}}
											label={
												<Typography>
													{formik.values.anticipationEnabled
														? t('status-tag-on')
														: t('status-tag-off')}
												</Typography>
											}
										/>
									</Box>
								)}
							</Stack>
							<Box sx={{mt: 4}}>
								<Typography variant="h6" sx={{mb: 2}}>
									{t('advanced-settings')}
								</Typography>
								<Accordion
									expanded={expandedPanel1}
									disableGutters
									square
									variant="outlined"
									onChange={() => {
										setExpandedPanel1(prev => !prev)
									}}
									sx={{
										borderTopLeftRadius: '4px',
										borderTopRightRadius: '4px',
										'&.MuiAccordion-root::before': {
											display: 'none'
										}
									}}
								>
									<AccordionSummary
										expandIcon={<ArrowDropDownIcon />}
										aria-controls="panel1-content"
										id="panel1-header"
										sx={{
											backgroundColor: 'grey.100',
											borderTopLeftRadius: '4px',
											borderTopRightRadius: '4px',
											'&.MuiAccordionSummary-content': {
												my: '12px !important'
											}
										}}
									>
										<Typography variant="subtitle2" fontWeight={500}>
											{t('customize-payment-page')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails
										sx={{
											borderTop: '1px solid',
											borderColor: 'divider',
											p: 2
										}}
									>
										<Stack spacing={2}>
											<Box
												sx={{
													display: 'flex',
													flexDirection: 'column',
													gap: 1.25
												}}
											>
												<Typography variant="subtitle1" fontWeight={500}>
													{t('checkout-type')}
												</Typography>
												<RadioGroup
													aria-labelledby="checkout-type-label"
													name="checkoutType"
													onChange={event =>
														setCheckoutType(
															event.target.value as 'one-page' | 'multiple-page'
														)
													}
												>
													<Stack direction="column" spacing={1}>
														<FormControlLabel
															value="one-page"
															checked={checkoutType === 'one-page'}
															control={
																<Radio
																	sx={{
																		fontSize: '0.875rem',
																		'&.MuiButtonBase-root': {
																			py: 0
																		}
																	}}
																/>
															}
															label={t('one-page-checkout')}
															sx={{color: 'text.secondary'}}
														/>
														<FormControlLabel
															value="multiple-page"
															checked={checkoutType === 'multiple-page'}
															control={
																<Radio
																	sx={{
																		'&.MuiButtonBase-root': {
																			py: 0
																		}
																	}}
																/>
															}
															label={t('multiple-page-checkout')}
															sx={{
																color: 'text.secondary'
															}}
														/>
													</Stack>
												</RadioGroup>
											</Box>
											<FormControl sx={{gap: 1.25}}>
												<Typography variant="subtitle1" fontWeight={500}>
													{t('enabled-payment-methods')}
												</Typography>
												<Stack direction="column" spacing={1}>
													<FormControlLabel
														control={
															<Checkbox
																sx={(theme: Theme) => ({
																	color: theme.palette.grey[500],
																	py: 0
																})}
																name="enabledCreditCard"
																checked={formik.values.enabledCreditCard}
																onChange={event => {
																	formik.handleChange(event)
																	validateEnabledPaymentMethods(
																		event.target.checked,
																		formik.values.enabledPix,
																		formik.values.enabledBankSlip
																	)
																}}
															/>
														}
														label={
															<Typography sx={{color: 'text.secondary'}}>
																{t('credit-card')}
															</Typography>
														}
														sx={{
															alignItems: 'flex-start',
															'&.MuiFormControlLabel-root': {
																mr: 0
															}
														}}
													/>
													<FormControlLabel
														control={
															<Checkbox
																sx={(theme: Theme) => ({
																	color: theme.palette.grey[500],
																	py: 0
																})}
																name="enabledPix"
																checked={formik.values.enabledPix}
																onChange={event => {
																	formik.handleChange(event)
																	validateEnabledPaymentMethods(
																		formik.values.enabledCreditCard,
																		event.target.checked,
																		formik.values.enabledBankSlip
																	)
																}}
															/>
														}
														label={
															<Typography sx={{color: 'text.secondary'}}>
																{t('pix')}
															</Typography>
														}
														sx={{
															alignItems: 'flex-start',
															'&.MuiFormControlLabel-root': {
																mr: 0
															}
														}}
													/>
													<FormControlLabel
														control={
															<Checkbox
																sx={(theme: Theme) => ({
																	color: theme.palette.grey[500],
																	py: 0
																})}
																name="enabledBankSlip"
																checked={formik.values.enabledBankSlip}
																onChange={event => {
																	formik.handleChange(event)
																	validateEnabledPaymentMethods(
																		formik.values.enabledCreditCard,
																		formik.values.enabledPix,
																		event.target.checked
																	)
																}}
															/>
														}
														label={
															<Typography sx={{color: 'text.secondary'}}>
																{t('bank-slip')}
															</Typography>
														}
														sx={{
															alignItems: 'flex-start',
															'&.MuiFormControlLabel-root': {
																mr: 0
															}
														}}
													/>
													{Boolean(enabledPaymentMethodsError) && (
														<FormHelperText
															ref={enabledPaymentMethodsErrorRef}
															error
														>
															{enabledPaymentMethodsError}
														</FormHelperText>
													)}
												</Stack>
											</FormControl>
											<FormControl sx={{gap: 1.25, py: 1}}>
												<Typography variant="subtitle1" fontWeight={500}>
													{t('allow-multiple-items')}
												</Typography>
												<FormControlLabel
													control={
														<Checkbox
															sx={(theme: Theme) => ({
																color: theme.palette.grey[500],
																py: 0
															})}
															name="allowMultipleItems"
															checked={formik.values.allowMultipleItems}
															onChange={formik.handleChange}
														/>
													}
													label={
														<Typography sx={{color: 'text.secondary'}}>
															{t('allow-multiple-items-label')}
														</Typography>
													}
													sx={{
														alignItems: 'flex-start',
														ml: 0
													}}
												/>
											</FormControl>
											<Box>
												<Box
													sx={{
														display: 'flex',
														alignItems: 'flex-start',
														flexWrap: 'wrap',
														rowGap: 3,
														flexDirection: 'column'
													}}
												>
													<InputQuantity
														label={t('max-installments')}
														min={1}
														max={12}
														onChange={value => {
															handleOnChangeForm()
															formik.setFieldValue('maxInstallments', value)
															if (value < formik.values.defaultInstallments) {
																formik.setFieldValue(
																	'defaultInstallments',
																	value
																)
															}
														}}
														value={formik.values.maxInstallments}
														infoLabel={t('max-installments-info', {
															numInstallments: formik.values.maxInstallments
														})}
													/>
													<InputQuantity
														label={t('default-installments')}
														min={1}
														max={formik.values.maxInstallments}
														onChange={value => {
															handleOnChangeForm()
															formik.setFieldValue('defaultInstallments', value)
														}}
														value={formik.values.defaultInstallments}
														infoLabel={t('default-installments-info', {
															numInstallments: formik.values.defaultInstallments
														})}
													/>
												</Box>
												<Box sx={{mt: 1}}>
													{Boolean(formik.errors.maxInstallments) && (
														<FormHelperText error={true}>
															{formik.errors.maxInstallments}
														</FormHelperText>
													)}
													{Boolean(formik.errors.defaultInstallments) && (
														<FormHelperText error={true}>
															{formik.errors.defaultInstallments}
														</FormHelperText>
													)}
												</Box>
											</Box>
										</Stack>
									</AccordionDetails>
								</Accordion>
								<Accordion
									disableGutters
									square
									variant="outlined"
									sx={{
										borderBottomLeftRadius: '4px',
										borderBottomRightRadius: '4px',
										borderTopWidth: 0,
										'&.MuiAccordion-root::before': {
											display: 'none'
										}
									}}
								>
									<AccordionSummary
										expandIcon={<ArrowDropDownIcon />}
										aria-controls="panel2-content"
										id="panel2-header"
										sx={{
											backgroundColor: 'grey.100',
											borderBottomLeftRadius: '4px',
											borderBottomRightRadius: '4px',
											'&.MuiAccordionSummary-content': {
												my: '12px !important'
											}
										}}
									>
										<Typography variant="subtitle2" fontWeight={500}>
											{t('customize-rate-application')}
										</Typography>
									</AccordionSummary>
									<AccordionDetails
										sx={{
											borderTop: '1px solid',
											borderColor: 'divider',
											p: 2
										}}
									>
										<Stack spacing={2}>
											<Typography mb={1}>
												{t('customize-rate-application-description')}
											</Typography>
											<CustomizeRateApplication
												paymentService={paymentService?.activeService}
												passProcessingFee={
													formik.values.passCreditCardFeesToCustomer
												}
												onChangePassProcessingFee={pass => {
													formik.setFieldValue(
														'passCreditCardFeesToCustomer',
														pass
													)
												}}
												passAnticipationFee={
													formik.values.passAnticipationFeesToCustomer
												}
												onChangePassAnticipationFee={pass => {
													formik.setFieldValue(
														'passAnticipationFeesToCustomer',
														pass
													)
												}}
												passExcofyFee={formik.values.passExcofyFeesToCustomer}
												onChangePassExcofyFee={pass => {
													formik.setFieldValue('passExcofyFeesToCustomer', pass)
												}}
												anticipationEnabled={formik.values.anticipationEnabled}
												onChangeAnticipationEnabled={enabled => {
													formik.setFieldValue('anticipationEnabled', enabled)
												}}
												additionalProfitAmount={
													formik.values.additionalProfitAmount
												}
												onChangeAdditionalProfitAmount={newAmount => {
													formik.setFieldValue(
														'additionalProfitAmount',
														newAmount
													)
												}}
												additionalProfitType={
													formik.values.additionalProfitType
												}
												onChangeAdditionalProfitType={newType => {
													formik.setFieldValue('additionalProfitType', newType)
												}}
												additionalProfitEnabled={
													formik.values.additionalProfitEnabled
												}
												onChangeAdditionalProfitEnabled={enabled => {
													formik.setFieldValue(
														'additionalProfitEnabled',
														enabled
													)
												}}
											/>
											<Button
												variant="outlined"
												startIcon={<CalculatorIcon />}
												onClick={() => setOpenSimulateSale(true)}
												sx={{alignSelf: 'flex-start'}}
											>
												{t('to-simulate-sale')}
											</Button>
										</Stack>
									</AccordionDetails>
								</Accordion>
							</Box>
							{showSuccessButton ? (
								<Button
									size="large"
									variant="contained"
									type="button"
									sx={style.form.successButton}
								>
									{t('saved-changes')}
								</Button>
							) : (
								<Button
									size="large"
									variant="contained"
									type="submit"
									disabled={isLoading}
									sx={style.form.submitButton}
								>
									{isLoading ? (
										<CircularProgress color="inherit" size={25} />
									) : (
										t('to-save-changes')
									)}
								</Button>
							)}
						</>
					)}
				</Box>
				{!isFetching && edition?.integrations && (
					<Box>
						<Typography variant="h5" mb={3}>
							{t('integrations')}
						</Typography>
						{edition?.integrations.orderBump && (
							<OrderBumpCard
								offerId={edition._id}
								productId={edition.experience._id}
								orderBump={edition?.orderBump}
								orderBumpIntegration={edition?.integrations.orderBump}
							/>
						)}
					</Box>
				)}
			</Stack>
		</Box>
	)
}

const style = {
	form: {
		box: (theme: Theme) => ({
			my: 5,
			mt: 0,
			mb: 5,
			pt: 0,
			width: '100%',
			maxWidth: '600px'
		}),
		control: (theme: Theme) => ({
			display: 'flex',
			flexDirection: 'row',
			width: '100%'
		}),
		controlDate: (theme: Theme) => ({
			gap: 6,
			[theme.breakpoints.down('sm')]: {
				gap: 4,
				flexDirection: 'column'
			}
		}),
		submitButton: (theme: Theme) => ({
			mt: 4,
			height: '44px',
			width: '200px',
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			}
		}),
		successButton: (theme: Theme) => ({
			mt: 4,
			height: '44px',
			backgroundColor: 'success.light',
			color: 'success.contrastText',
			width: '200px',
			[theme.breakpoints.down('sm')]: {
				width: '100%'
			},
			'&:hover': {backgroundColor: 'success.light'}
		}),
		inputCheckIcon: (theme: Theme) => ({
			color: theme.palette.success.main,
			p: 0
		}),
		inputDate: {
			flexGrow: 1
		}
	},
	boxInfoPopover: {
		position: 'relative',
		mt: 0.375
	}
}

export default FormUpdateEdition
