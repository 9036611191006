import {useEffect, useState} from 'react'
import useAuthStates from '../../stores/useAuthStates'
import useColorMode from '../../hooks/useColorMode'
import {image} from '../../helpers/image'
import {Box, Skeleton} from '@mui/material'

export const CompanyLogo = ({
	maxHeight = '2.8rem',
	maxWidth = '190px'
}: {
	maxHeight?: string
	maxWidth?: string
}) => {
	const [logo, setLogo] = useState<string | undefined>(undefined)
	const profile = useAuthStates(state => state.profile)
	const colorMode = useColorMode()

	useEffect(() => {
		if (profile) {
			const loadLogo = async () => {
				if (colorMode === 'dark') {
					const logoDark = await image.loadImage(profile.logoDark.url)
					setLogo(logoDark.src)
				}

				if (colorMode === 'light') {
					const logoLight = await image.loadImage(profile.logoLight.url)
					setLogo(logoLight.src)
				}
			}

			loadLogo()
		}
	}, [colorMode, profile])

	return (
		<Box
			sx={{
				display: 'flex',
				alignItems: 'center'
			}}
		>
			{logo ? (
				<img
					src={logo}
					alt="Logo of the company"
					style={{
						maxHeight,
						maxWidth
					}}
				/>
			) : (
				<Skeleton variant="rounded" height="2.8rem" width={110} />
			)}
		</Box>
	)
}
