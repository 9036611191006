import Logo from './Logo'
import Typography from '@mui/material/Typography'
import {Theme} from '@mui/material/styles'
import Stack from '@mui/material/Stack'
import useColorMode from '../../hooks/useColorMode'

interface LogoTypeProps {
	logoSize?: string
	typographyVariant?:
		| 'h1'
		| 'h2'
		| 'h3'
		| 'h4'
		| 'h5'
		| 'h6'
		| 'subtitle1'
		| 'subtitle2'
		| 'body1'
		| 'body2'
		| 'caption'
		| 'button'
		| 'overline'
		| 'inherit'
		| undefined
	marginTopTypo?: string | number
	monoColorLight?: string
	monoColorDark?: string
	spacing?: number
}

const LogoType = ({
	logoSize = '2.8rem',
	typographyVariant = 'h5',
	marginTopTypo = 1,
	monoColorLight,
	monoColorDark,
	spacing = 1
}: LogoTypeProps) => {
	const colorMode = useColorMode()
	const monoColor = colorMode === 'light' ? monoColorLight : monoColorDark
	return (
		<Stack direction="row" spacing={spacing} alignItems="center">
			<Logo sx={() => style.iconLogo(logoSize, colorMode, monoColor)} />
			<Typography
				variant={typographyVariant}
				component="h1"
				sx={theme => style.typo(theme, marginTopTypo, monoColor)}
			>
				Excofy
			</Typography>
		</Stack>
	)
}

const style = {
	iconLogo: (size: string, colorMode: string, monoColor?: string) => {
		return {
			height: size,
			width: size,
			'& g polygon': {
				fill: colorMode === 'light' ? '#000 !important' : '#fff !important'
			},
			'& g rect, g polygon': {
				fill: monoColor ? monoColor + ' !important' : 'inherit'
			}
		}
	},
	typo: (theme: Theme, marginTop?: string | number, monoColor?: string) => {
		return {
			color: monoColor ? monoColor : theme.palette.grey[900],
			fontWeight: '700',
			paddingTop: marginTop !== undefined ? marginTop : 1
		}
	}
}

export default LogoType
