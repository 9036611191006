import {ThemeProvider as Theme, createTheme} from '@mui/material/styles'
import {light as platformLightTheme} from '../themes/light'
import {dark as platformDarkTheme} from '../themes/dark'
import useColorMode from '../hooks/useColorMode'
import useAuthStates from '../stores/useAuthStates'
import {useEffect, useMemo, useState} from 'react'
import {
	colorConvert,
	generateColorDark,
	generateColorLight
} from '../helpers/colorConvert'

interface ThemeProviderProps {
	defaultTheme?: 'dark' | 'light'
	children?: React.ReactNode
}

const ThemeProvider = ({
	children,
	defaultTheme = 'light'
}: ThemeProviderProps) => {
	const mode = useColorMode()
	const {profile, isLogged} = useAuthStates(state => state)
	const [primaryColor, setPrimaryColor] = useState(
		profile?.primaryColor || platformLightTheme.palette.primary.main
	)

	const lightTheme = useMemo(() => {
		let newPrimaryColor = primaryColor

		const nearColor = colorConvert.isNearBlackOrWhite(primaryColor)

		if (nearColor === 'white') {
			newPrimaryColor = generateColorDark(newPrimaryColor, 25)
		}

		const primaryColorLight = generateColorLight(newPrimaryColor, 25)
		const primaryColorDark = generateColorDark(newPrimaryColor, 25)

		return createTheme(platformLightTheme, {
			palette: {
				primary: {
					main: newPrimaryColor,
					light: primaryColorLight,
					dark: primaryColorDark
				}
			},
			components: {
				MuiLink: {
					styleOverrides: {
						root: {
							color: primaryColorDark
						}
					}
				},
				MuiButton: {
					...platformLightTheme.components?.MuiButton,
					variants: [
						{
							props: {variant: 'text'},
							style: {
								color: primaryColorDark,
								'&:hover': {
									boxShadow: 'none'
								}
							}
						},
						{
							props: {variant: 'contained'},
							style: {
								color: colorConvert.isDark(newPrimaryColor) ? '#FFF' : '#000',
								'&:hover': {
									boxShadow: 'none'
								}
							}
						},
						{
							props: {variant: 'outlined'},
							style: {
								borderColor: platformLightTheme.palette.grey[600],
								color: platformLightTheme.palette.grey[800],
								'&:hover': {
									color: platformLightTheme.palette.grey[900],
									borderColor: primaryColorDark
								}
							}
						}
					]
				}
			}
		})
	}, [primaryColor])

	const darkTheme = useMemo(() => {
		let newPrimaryColor = primaryColor

		const nearColor = colorConvert.isNearBlackOrWhite(primaryColor)

		if (nearColor === 'black') {
			newPrimaryColor = generateColorLight(newPrimaryColor, 75)
		}

		const primaryColorLight = generateColorLight(newPrimaryColor, 25)
		const primaryColorDark = generateColorDark(newPrimaryColor, 25)

		return createTheme(platformDarkTheme, {
			palette: {
				primary: {
					main: newPrimaryColor,
					light: primaryColorLight,
					dark: primaryColorDark
				}
			},
			components: {
				MuiLink: {
					styleOverrides: {
						root: {
							color: primaryColorDark
						}
					}
				},
				MuiButton: {
					...platformDarkTheme.components?.MuiButton,
					variants: [
						{
							props: {variant: 'text'},
							style: {
								color: primaryColorDark,
								'&:hover': {
									boxShadow: 'none'
								}
							}
						},
						{
							props: {variant: 'contained'},
							style: {
								color: colorConvert.isDark(newPrimaryColor) ? '#FFF' : '#000',
								'&:hover': {
									boxShadow: 'none'
								}
							}
						},
						{
							props: {variant: 'outlined'},
							style: {
								borderColor: platformDarkTheme.palette.grey[300],
								color: platformDarkTheme.palette.grey[800],
								'&:hover': {
									color: platformDarkTheme.palette.grey[900],
									borderColor: primaryColorDark
								}
							}
						}
					]
				}
			}
		})
	}, [primaryColor])

	useEffect(() => {
		if (!isLogged) {
			setPrimaryColor(platformLightTheme.palette.primary.main)
		} else if (profile?.primaryColor) {
			setPrimaryColor(profile?.primaryColor)
		}
	}, [profile, isLogged])

	return (
		<Theme
			theme={
				mode === 'light' && defaultTheme === 'light' ? lightTheme : darkTheme
			}
		>
			{children}
		</Theme>
	)
}

export default ThemeProvider
