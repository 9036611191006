import {create} from 'zustand'
import {BankAccount} from '../entities/BankAccount'
import {CompanyLegalRepresentative} from '../entities/CompanyLegalRepresentative'
import {TransferSettings} from '../entities/PagarmeConfig'

type PagarmeStatus =
	| 'pending-setup'
	| 'pending-activation'
	| 'in-review'
	| 'rejected'
	| 'active'
	| 'deactivated'
	| 'approved'

export type PagarmeRecipientStatus =
	| 'registration'
	| 'affiliation'
	| 'active'
	| 'rejected'

export interface PagarmeRecipientBalance {
	availableAmount: number
	availableAmountToTransfer: number
	waitingFundsAmount: number
}

export interface PagarmeTransfer {
	amount: number
	feeAmount: number
	accountCheckDigit: string
	accountNumber: string
	accountType: 'checking' | 'savings'
	bankCode: string
	branchCheckDigit?: string
	branchNumber: string
	createdAt: string
	id: string
	status: string
	statusHistory: {
		status: string
		log?: string
		createdAt: string
	}[]
}

export interface PagarmeStates {
	status: PagarmeStatus
	recipientStatusHistory: {status: PagarmeRecipientStatus; log?: string}[]
	transferSettings: TransferSettings | null
	bankAccount: BankAccount | null
	balance: PagarmeRecipientBalance | null
	companyAnnualRevenue: number | null
	companyLegalRepresentative: CompanyLegalRepresentative | null
	transfers: PagarmeTransfer[]
	setStatus: (status: PagarmeStates['status']) => void
	setRecipientStatusHistory: (
		statusHistory: {status: PagarmeRecipientStatus; log?: string}[]
	) => void
	setTransferSettings: (data: Partial<TransferSettings> | null) => void
	setBankAccount: (bankAccount: BankAccount | null) => void
	setBalance: (balance: Partial<PagarmeRecipientBalance>) => void
	setCompanyAnnualRevenue: (companyAnnualRevenue: number | null) => void
	setCompanyLegalRepresentative: (
		companyLegalRepresentative: CompanyLegalRepresentative | null
	) => void
	setPaymentServicePagarme: (params: {
		status: PagarmeStates['status']
		transferSettings: TransferSettings
		bankAccount: BankAccount
		companyAnnualRevenue: number
		companyLegalRepresentative: CompanyLegalRepresentative
	}) => void
	setTransfers: (transfers: PagarmeTransfer[]) => void
	addTransfer: (transfer: PagarmeTransfer) => void
	reset: () => void
}

const usePaymentServicePagarmeStates = create<PagarmeStates>()(set => ({
	status: 'pending-activation',
	transferSettings: {
		automaticTransferEnabled: false,
		transferInterval: 'Monthly' as const,
		transferMonthDay: 1,
		transferWeekDay: 1
	},
	recipientStatusHistory: [],
	bankAccount: null,
	companyAnnualRevenue: null,
	companyLegalRepresentative: null,
	balance: null,
	transfers: [],
	setStatus: status => set({status}),
	setTransferSettings: transferSettings =>
		set(state => ({
			transferSettings: {
				automaticTransferEnabled:
					transferSettings?.automaticTransferEnabled ?? false,
				transferInterval: transferSettings?.transferInterval ?? 'Monthly',
				transferMonthDay: transferSettings?.transferMonthDay ?? 1,
				transferWeekDay: transferSettings?.transferWeekDay
			}
		})),
	setRecipientStatusHistory: statusHistory =>
		set({recipientStatusHistory: statusHistory}),

	setBankAccount: bankAccount => set({bankAccount}),
	setCompanyAnnualRevenue: companyAnnualRevenue => set({companyAnnualRevenue}),
	setCompanyLegalRepresentative: companyLegalRepresentative =>
		set({companyLegalRepresentative}),
	setPaymentServicePagarme: ({
		status,
		transferSettings,
		bankAccount,
		companyAnnualRevenue,
		companyLegalRepresentative
	}) => {
		set({
			status,
			transferSettings,
			bankAccount,
			companyAnnualRevenue,
			companyLegalRepresentative
		})
	},
	setBalance: balance =>
		set(state => ({
			balance: {
				availableAmount:
					balance.availableAmount ?? state.balance?.availableAmount ?? 0,
				availableAmountToTransfer:
					balance.availableAmountToTransfer ??
					state.balance?.availableAmountToTransfer ??
					0,
				waitingFundsAmount:
					balance.waitingFundsAmount ?? state.balance?.waitingFundsAmount ?? 0
			}
		})),
	setTransfers: transfers => set({transfers}),
	addTransfer: transfer =>
		set(state => ({transfers: [...state.transfers, transfer]})),
	reset: () =>
		set({
			status: 'pending-activation',
			transferSettings: {
				automaticTransferEnabled: false,
				transferInterval: 'Monthly',
				transferMonthDay: 1,
				transferWeekDay: 1
			},
			bankAccount: null,
			companyAnnualRevenue: null,
			companyLegalRepresentative: null,
			balance: null,
			transfers: []
		})
}))

export default usePaymentServicePagarmeStates
