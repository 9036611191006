import {useState} from 'react'
import {useMutation} from '@tanstack/react-query'
import {useTranslation} from 'react-i18next'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {
	Button,
	CircularProgress,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Stack
} from '@mui/material'
import useAuthStates from '../../../stores/useAuthStates'
import {regex} from '../../../helpers/regex'
import {createAsaasSubAccount} from '../../../api/payment-service/asaas'
import AlertMessage from '../../../components/AlertMessage'
import useAsaasStates from '../../../stores/useAsaasStates'
import InputMask from '../../../components/InputMask'

export const AsaasCommercialDataStep = ({
	onSuccess
}: {
	onSuccess: () => void
}) => {
	const [openAlert, setOpenAlert] = useState(false)
	const [alertMessage, setAlertMessage] = useState('')

	const {t} = useTranslation()
	const {company} = useAuthStates(state => state)
	const {setAsaasConfig} = useAsaasStates()

	const validationSchema = yup.object({
		companyType: yup
			.string()
			.required(t('error-company-type-required'))
			.nullable()
			.oneOf(
				['MEI', 'LIMITED', 'INDIVIDUAL', 'ASSOCIATION'],
				t('error-company-type-invalid')
			),
		incomeValue: yup.string().required(t('error-monthly-revenue-required')),
		phoneNumber: yup
			.string()
			.required(t('error-phone-required'))
			.length(11, t('error-phone-invalid'))
	})

	const {isLoading, mutate} = useMutation(createAsaasSubAccount, {
		networkMode: 'always'
	})

	const formik = useFormik({
		initialValues: {
			companyType: 'MEI',
			incomeValue: '',
			phoneNumber: ''
		},
		validationSchema,
		onSubmit: values => {
			setOpenAlert(false)
			mutate(
				{
					companyType: values.companyType,
					incomeValue: regex.parseBRLCurrencyToFloat(values.incomeValue),
					phoneNumber: values.phoneNumber
				},
				{
					onSuccess: ({error, responseStatusCode}) => {
						if (responseStatusCode === 201) {
							setAsaasConfig({
								commercialStatus: 'awaiting-approval',
								companyType: values.companyType,
								incomeValue: regex.parseBRLCurrencyToFloat(values.incomeValue),
								documentStatus: 'pending',
								generalStatus: 'pending',
								onboardingStep: 2
							})
							onSuccess()
						} else if (error?.fields) {
							if (error.fields.companyType) {
								formik.setFieldError(
									'companyType',
									error.fields.companyType[0].message
								)
							}
							if (error.fields.incomeValue) {
								formik.setFieldError(
									'incomeValue',
									error.fields.incomeValue[0].message
								)
							}
						} else {
							setOpenAlert(true)
							setAlertMessage(error?.message || t('error-server-default'))
						}
					},
					onError: error => {
						setOpenAlert(true)
						setAlertMessage(t('error-server-default'))
						console.error(error)
					}
				}
			)
		}
	})

	return (
		<>
			<AlertMessage
				open={openAlert}
				severity="error"
				message={alertMessage}
				onClose={() => setOpenAlert(false)}
			/>
			<Stack spacing={3} mt={4}>
				<TextField
					name="cnpj"
					type="text"
					autoFocus
					label={t('cnpj')}
					variant="outlined"
					margin="normal"
					value={regex.formatCNPJ(company?.cnpj as string)}
					size="small"
					disabled={true}
				/>
				<FormControl fullWidth size="small">
					<InputLabel id="simple-select-label">{t('company-type')}</InputLabel>
					<Select
						name="companyType"
						label={t('company-type')}
						labelId="simple-select-label"
						value={formik.values.companyType}
						onChange={formik.handleChange}
					>
						<MenuItem value="MEI">{t('company-type-mei')}</MenuItem>
						<MenuItem value="LIMITED">{t('company-type-limited')}</MenuItem>
						<MenuItem value="INDIVIDUAL">
							{t('company-type-individual')}
						</MenuItem>
						<MenuItem value="ASSOCIATION">
							{t('company-type-association')}
						</MenuItem>
					</Select>
				</FormControl>
				<TextField
					name="incomeValue"
					type="text"
					label={t('monthly-revenue')}
					variant="outlined"
					helperText={formik.touched.incomeValue && formik.errors.incomeValue}
					error={
						formik.touched.incomeValue && Boolean(formik.errors.incomeValue)
					}
					margin="normal"
					value={formik.values.incomeValue}
					onChange={event => {
						event.target.value = regex.formatBRLCurrency(event.target.value)
						formik.handleChange(event)
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">{'R$'}</InputAdornment>
						)
					}}
					onBlur={formik.handleBlur}
					size="small"
				/>
				<TextField
					name="phoneNumber"
					type="text"
					label={t('phone-area-code-and-mobile-phone-number')}
					variant="outlined"
					helperText={formik.touched.phoneNumber && formik.errors.phoneNumber}
					error={
						formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)
					}
					value={formik.values.phoneNumber}
					onChange={event => {
						const value = regex.removeAllNonDigits(event.target.value)
						event.target.value = value
						formik.handleChange(event)
					}}
					onBlur={event => {
						const value = regex.removeAllNonDigits(event.target.value)
						event.target.value = value
						formik.handleBlur(event)
					}}
					InputProps={{
						inputComponent: InputMask as any,
						inputProps: {
							mask: '(00) 00000-0000'
						}
					}}
					inputProps={{
						inputMode: 'numeric'
					}}
					size="small"
				/>
				<div>
					<Button
						variant="contained"
						disabled={isLoading}
						onClick={formik.submitForm}
						sx={{
							height: '36.5px',
							width: '120px'
						}}
					>
						{isLoading ? (
							<CircularProgress size={24} color="inherit" />
						) : (
							t('to-continue')
						)}
					</Button>
				</div>
			</Stack>
		</>
	)
}
