import {styled} from '@mui/system'
import {FaPlus as AddIcon} from 'react-icons/fa6'
import {FaMinus as RemoveIcon} from 'react-icons/fa6'
import {Box, Button, Typography} from '@mui/material'
import {useTheme, Theme} from '@mui/material/styles'
import InfoPopover from './InfoPopover'

export default function InputQuantity({
	label,
	infoLabel,
	max,
	min,
	value,
	onChange
}: {
	label: string
	infoLabel?: string
	max?: number
	min?: number
	value: number
	onChange: (value: number) => void
}) {
	const theme = useTheme()

	const StyledInput = styled('input')(
		() => `
  font-size: 0.875rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.375;
  color: ${
		theme.palette.mode === 'dark'
			? theme.palette.grey[300]
			: theme.palette.grey[900]
	};
  background: ${theme.palette.common.white};
  border: 1px solid ${theme.palette.grey[300]};
  box-shadow: 0px 2px 4px ${
		theme.palette.mode === 'dark' ? 'rgba(0,0,0, 0.5)' : 'rgba(0,0,0, 0.05)'
	};
  border-radius: 8px;
  margin: 0 8px;
  padding: 6px 12px;
  outline: 0;
  min-width: 0;
  width: 3rem;
  text-align: center;

  &:hover {
    border-color: ${theme.palette.primary.main};
  }

  &:focus {
    border-color: ${theme.palette.primary.main};
    box-shadow: 0 0 0 3px ${
			theme.palette.mode === 'dark'
				? theme.palette.primary.dark
				: theme.palette.primary.main
		};
  }

  &:focus-visible {
    outline: 0;
  }
`
	)

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				ml: 0,
				gap: 1
			}}
		>
			<Box sx={{display: 'inline-flex', alignItems: 'center'}}>
				<Typography variant="subtitle1" fontWeight={500}>
					{label}
				</Typography>
				{infoLabel && <InfoPopover>{infoLabel}</InfoPopover>}
			</Box>
			<Box
				sx={{
					fontWeight: 400,
					color: 'grey.300',
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					ml: 1
				}}
			>
				<Button
					sx={styles.button}
					onClick={() => {
						if (!min || (min && value > min)) {
							onChange(value - 1)
						}
					}}
				>
					<RemoveIcon fontSize="small" />
				</Button>
				<StyledInput value={value} disabled />
				<Button
					sx={styles.button}
					onClick={() => {
						if (!max || (max && value < max)) {
							onChange(value + 1)
						}
					}}
				>
					<AddIcon fontSize="small" />
				</Button>
			</Box>
		</Box>
	)
}

const styles = {
	button: (theme: Theme) => ({
		fontSize: '0.875rem',
		boxSizing: 'border-box',
		lineHeight: '1.5',
		border: '1px solid',
		borderRadius: '50%',
		borderColor:
			theme.palette.mode === 'dark'
				? theme.palette.grey[800]
				: theme.palette.grey[300],
		background:
			theme.palette.mode === 'dark'
				? theme.palette.grey[900]
				: theme.palette.grey[50],
		color:
			theme.palette.mode === 'dark'
				? theme.palette.grey[200]
				: theme.palette.grey[900],
		width: '32px',
		height: '32px',
		minWidth: '32px',
		display: 'flex',
		alignItems: 'center',
		transitionProperty: 'all',
		transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
		transitionDuration: '120ms',
		'&:hover': {
			cursor: 'pointer',
			background: theme.palette.primary.main,
			borderColor: theme.palette.primary.main,
			color: theme.palette.grey[50]
		},
		'&:focus-visible': {
			outline: 0,
			borderColor: theme.palette.primary.main,
			boxShadow: `0 0 0 3px ${
				theme.palette.mode === 'dark'
					? theme.palette.primary.dark
					: theme.palette.primary.main
			}`
		}
	})
}
