import {
	Box,
	Paper,
	Typography,
	FormControlLabel,
	Checkbox,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	Stack
} from '@mui/material'
import {AsaasLogo} from '../../logos/AsaasLogo'
import {useTranslation} from 'react-i18next'
import {regex} from '../../../helpers/regex'
import {EXCOFY_FEE, PS_FEES_ASAAS, PS_FEES_PAGARME} from '../../../config'
import useAppStates from '../../../stores/useAppStates'
import {price} from '../../../helpers/price'
import LogoType from '../../template/LogoType'
import {PagarmeLogo} from '../../logos/PagarmeLogo'
import {CompanyLogo} from '../../logos/CompanyLogo'
import parseToHTML from 'html-react-parser'
import SwitchControl from '../../SwitchControl'
import InfoPopover from '../../InfoPopover'
import InputPercentage from '../../InputPercentage'

const Head = ({children}: {children: React.ReactNode}) => {
	return (
		<Box
			sx={theme => ({
				backgroundColor: theme.palette.grey[100],
				width: '100%',
				py: 1,
				px: 1.5,
				borderBottom: '1px solid',
				borderBottomColor: 'divider'
			})}
		>
			{children}
		</Box>
	)
}

const Row = ({
	children,
	border = true
}: {
	children: React.ReactNode
	border?: boolean
}) => {
	return (
		<Box
			sx={theme => ({
				width: '100%',
				borderBottom: border ? '1px solid' : undefined,
				borderBottomColor: border ? 'divider' : undefined
			})}
		>
			<Box
				sx={theme => ({
					display: 'flex',
					alignItems: 'flex-start',
					flexWrap: 'wrap',
					gap: 5,
					py: 2,
					px: 1.5,
					'& > div:first-child': {
						width: 200
					},
					'& > div:last-child': {
						flex: 1,
						alignSelf: 'center'
					},
					[theme.breakpoints.down('sm')]: {
						flexDirection: 'column',
						'& > div:first-child': {
							width: 'auto'
						},
						'& > div:last-child': {
							flex: 1,
							alignSelf: 'flex-start'
						}
					}
				})}
			>
				{children}
			</Box>
		</Box>
	)
}

export const CustomizeRateApplication = ({
	paymentService,
	passProcessingFee,
	onChangePassProcessingFee,
	passAnticipationFee,
	onChangePassAnticipationFee,
	passExcofyFee,
	onChangePassExcofyFee,
	anticipationEnabled,
	onChangeAnticipationEnabled,
	additionalProfitType,
	onChangeAdditionalProfitType,
	additionalProfitAmount,
	onChangeAdditionalProfitAmount,
	additionalProfitEnabled,
	onChangeAdditionalProfitEnabled
}: {
	paymentService?: 'asaas' | 'pagarme'
	passProcessingFee: boolean
	onChangePassProcessingFee: (pass: boolean) => void
	passAnticipationFee: boolean
	onChangePassAnticipationFee: (pass: boolean) => void
	passExcofyFee: boolean
	onChangePassExcofyFee: (pass: boolean) => void
	anticipationEnabled: boolean
	onChangeAnticipationEnabled: (enabled: boolean) => void
	additionalProfitType: string
	onChangeAdditionalProfitType: (newType: string) => void
	additionalProfitAmount: number
	onChangeAdditionalProfitAmount: (newAmount: number) => void
	additionalProfitEnabled: boolean
	onChangeAdditionalProfitEnabled: (enabled: boolean) => void
}) => {
	const {t} = useTranslation()
	const {currentLang} = useAppStates()

	return (
		<Box display="grid">
			<Paper variant="outlined">
				<Box>
					{paymentService === 'asaas' && (
						<>
							<Head>
								<AsaasLogo height={24} />
							</Head>
							<Row>
								<Box width={250}>
									<Typography variant="subtitle1" mb={0.5}>
										{t('credit-card-processing-fee')}
									</Typography>
									<Box>
										<Box>
											<Typography variant="body2" sx={styles.paymentFeesAmount}>
												{regex.formatPercentage(
													PS_FEES_ASAAS.mdr[2],
													currentLang
												)}
												{' + '}
												{price.full(PS_FEES_ASAAS.fixedFee)}{' '}
											</Typography>
											<Typography variant="caption">
												{t('installment-up-to-twelve')}
											</Typography>
										</Box>
										<Box>
											<Typography variant="body2" sx={styles.paymentFeesAmount}>
												{regex.formatPercentage(
													PS_FEES_ASAAS.mdr[7],
													currentLang
												)}
												{' + '}
												{price.full(PS_FEES_ASAAS.fixedFee)}{' '}
											</Typography>
											<Typography variant="caption">
												{t('installment-up-to-six')}
											</Typography>
										</Box>
									</Box>
								</Box>
								<Box>
									<FormControlLabel
										control={
											<Checkbox
												sx={theme => ({
													color: theme.palette.grey[500],
													py: 0,
													pl: 0,
													pr: 0.5
												})}
												name="passCreditCardFeesToCustomer"
												checked={passProcessingFee}
												onChange={event => {
													onChangePassProcessingFee(event.target.checked)
												}}
											/>
										}
										label={
											<Typography
												sx={{color: 'text.secondary', lineHeight: '20px'}}
											>
												{t('to-pass-processing-fee')}
											</Typography>
										}
										sx={{m: 0, alignItems: 'flex-start'}}
									/>
								</Box>
							</Row>
							<Row>
								<Box>
									<Typography variant="subtitle1" sx={{textWrap: 'nowrap'}}>
										{t('payments-anticipation')}{' '}
										<InfoPopover
											sx={{display: 'inline-block'}}
											iconSx={{
												p: 0,
												mt: '-3px',
												width: '16px'
											}}
										>
											{t('automatic-anticipation-describe-2')}
										</InfoPopover>
									</Typography>
									<SwitchControl
										checked={anticipationEnabled}
										onChange={event => {
											onChangeAnticipationEnabled(event.target.checked)
										}}
										label={
											<Typography variant="body2">
												{anticipationEnabled
													? t('status-tag-on')
													: t('status-tag-off')}
											</Typography>
										}
									/>
									<Typography variant="body2" mt={1}>
										{parseToHTML(
											t('automatic-anticipation-fee', {
												fee: regex.formatPercentage(
													PS_FEES_ASAAS.anticipationMonthlyFee,
													currentLang
												)
											})
										)}
									</Typography>
								</Box>
								<Box>
									<FormControlLabel
										control={
											<Checkbox
												sx={theme => ({
													color: theme.palette.grey[500],
													py: 0,
													pl: 0,
													pr: 0.5
												})}
												name="passCreditCardFeesToCustomer"
												checked={passAnticipationFee}
												onChange={event => {
													onChangePassAnticipationFee(event.target.checked)
												}}
											/>
										}
										label={
											<Typography
												sx={{color: 'text.secondary', lineHeight: '20px'}}
											>
												{t('to-pass-anticipation-fee')}
											</Typography>
										}
										sx={{mt: 1, mx: 0, alignItems: 'flex-start'}}
									/>
								</Box>
							</Row>
						</>
					)}
					{paymentService === 'pagarme' && (
						<>
							<Head>
								<PagarmeLogo height={28} />
							</Head>
							<Row>
								<Box>
									<Typography variant="subtitle1" mb={0.5}>
										{t('credit-card-processing-fee')}
									</Typography>
									<Box>
										<Box>
											<Typography variant="body2" sx={styles.paymentFeesAmount}>
												{regex.formatPercentage(
													PS_FEES_PAGARME.mdr[1],
													currentLang
												)}
												{' + '}
												{price.full(PS_FEES_PAGARME.fixedFee)}{' '}
											</Typography>
											<Typography variant="caption">
												{t('installment-one')}
											</Typography>
										</Box>
										<Box>
											<Typography variant="body2" sx={styles.paymentFeesAmount}>
												{regex.formatPercentage(
													PS_FEES_PAGARME.mdr[2],
													currentLang
												)}
												{' + '}
												{price.full(PS_FEES_PAGARME.fixedFee)}{' '}
											</Typography>
											<Typography variant="caption">
												{t('installment-up-to-twelve')}
											</Typography>
										</Box>
										<Box>
											<Typography variant="body2" sx={styles.paymentFeesAmount}>
												{regex.formatPercentage(
													PS_FEES_PAGARME.mdr[7],
													currentLang
												)}
												{' + '}
												{price.full(PS_FEES_PAGARME.fixedFee)}{' '}
											</Typography>
											<Typography variant="caption">
												{t('installment-up-to-six')}
											</Typography>
										</Box>
									</Box>
								</Box>
								<Box>
									<FormControlLabel
										control={
											<Checkbox
												sx={theme => ({
													color: theme.palette.grey[500],
													py: 0,
													pl: 0,
													pr: 0
												})}
												name="passCreditCardFeesToCustomer"
												checked={passProcessingFee}
												onChange={event => {
													onChangePassProcessingFee(event.target.checked)
												}}
											/>
										}
										label={
											<Typography
												sx={{color: 'text.secondary', lineHeight: '20px'}}
											>
												{t('to-pass-processing-fee')}
											</Typography>
										}
										sx={{m: 0, alignItems: 'flex-start'}}
									/>
								</Box>
							</Row>
						</>
					)}
					<Head>
						<LogoType
							logoSize="2rem"
							typographyVariant="h6"
							marginTopTypo={0}
							spacing={0.5}
						/>
					</Head>
					<Row>
						<Box>
							<Typography variant="subtitle1" mb={0.5}>
								{t('sales-commission-fee')}
							</Typography>
							<Typography variant="body2">
								{parseToHTML(
									t('sales-commission-fee-percentage', {
										percentage: regex.formatPercentage(EXCOFY_FEE, currentLang)
									})
								)}
							</Typography>
						</Box>
						<Box>
							<FormControlLabel
								control={
									<Checkbox
										sx={theme => ({
											color: theme.palette.grey[500],
											py: 0,
											pl: 0,
											pr: 0.5
										})}
										name="passCreditCardFeesToCustomer"
										checked={passExcofyFee}
										onChange={event => {
											onChangePassExcofyFee(event.target.checked)
										}}
									/>
								}
								label={
									<Typography
										sx={{color: 'text.secondary', lineHeight: '20px'}}
									>
										{t('to-pass-excofy-fee')}
									</Typography>
								}
								sx={{m: 0}}
							/>
						</Box>
					</Row>
					<Head>
						<CompanyLogo maxHeight="2rem" />
					</Head>
					<Row border={false}>
						<Box sx={{display: 'flex'}}>
							<Box>
								<Typography variant="subtitle1" mb={0.5}>
									{t('additional-profit-on-sale')}
								</Typography>
								<SwitchControl
									checked={additionalProfitEnabled}
									onChange={event => {
										onChangeAdditionalProfitEnabled(event.target.checked)
									}}
									label={
										<Typography variant="body2">
											{additionalProfitEnabled
												? t('status-tag-on')
												: t('status-tag-off')}
										</Typography>
									}
								/>
							</Box>
						</Box>
						<Box my={1}>
							<Stack direction="column" spacing={2}>
								<FormControl
									size="small"
									sx={{
										width: '100%'
									}}
								>
									<InputLabel id="select-fee-type-label">
										{t('fee-type')}
									</InputLabel>
									<Select
										labelId="select-fee-type-label"
										id="select-fee-type"
										name="additionalProfitType"
										variant="outlined"
										value={additionalProfitType}
										label={t('fee-type')}
										onChange={event => {
											onChangeAdditionalProfitType(event.target.value)
										}}
										sx={{
											width: '100%',
											maxWidth: 250
										}}
									>
										<MenuItem value="monthly-interest-rate">
											{t('monthly-interest-rate')}
										</MenuItem>
										<MenuItem value="percentage-fee">
											{t('percentage-fee-on-gross-sales-amount')}
										</MenuItem>
									</Select>
								</FormControl>
								<InputPercentage
									label={t('fee')}
									initialValue={additionalProfitAmount}
									onChange={newValue => {
										onChangeAdditionalProfitAmount(newValue)
									}}
									sx={{
										width: '100%',
										maxWidth: 250
									}}
								/>
							</Stack>
						</Box>
					</Row>
				</Box>
			</Paper>
		</Box>
	)
}

const styles = {
	paymentFeesAmount: {
		display: 'inline',
		fontWeight: 500
	}
}
